// https://www.figma.com/file/LDMGcVwcgctqzREoowTNjB/Design-system?type=design&node-id=4354%3A2506&mode=design&t=N6zFfzlyOahz5gDc-1
export const colors = {
  // Accent
  frecBlue: "#000AFF",
  frecNeon: "#DCFE52",
  // Whites, Grays, neutrals
  frecBeige: "#FDFCF7",
  frecDarkBeige: "#E9E5DD",
  frecNearBlack: "#111111",
  // These values will adjust for light/dark according to index.css
  frecWhite: "rgb(var(--color-White))",
  frecBlack: "rgb(var(--color-Black))",
  frecXXLightGray: "rgb(var(--color-XXLightGray))",
  frecXLightGray: "rgb(var(--color-XLightGray))",
  frecLightGray: "rgb(var(--color-LightGray))",
  frecMidGray: "rgb(var(--color-MidGray))",
  frecDarkGray: "rgb(var(--color-DarkGray))",
  frecXDarkGray: "rgb(var(--color-XDarkGray))",
  // Informative
  frecGreen: "#08B00C",
  frecRed: "#EB1F1F",
  frecDarkRed: "#C82828",
  frecLightRed: "#FFE1E1",
  frecYellow: "#EBC051",
  frecLightYellow: "#FFFADE",
  frecDarkYellow: "#B08003",
  // Undocumented
  frecFuchsia: "#F266FF",
  frecCream: "#F4F3EE",
  frecSalmon: "#FF7456",
  frecTeal: "#00BCD6",
  frecPurple: "#7F52FE",
  frecDenimBlue: "#547ED1",
  frecDustyRose: "#B86070",
  frecPlum: "#B180B6",
  frecAppleGreen: "#89AD23",
  frecBronze: "#BD7F2A",
  frecDarkTeal: "#28666E",
  frecRust: "#DD4B1A",
  frecMagenta: "#A21E6E",
  frecGrape: "#78328A",
  frecSeaGreen: "#4C9B75",
  frecTreasury: "#69604D",
};

export const tailwindColors = {
  ...colors,
  // Tailwind uses this custom syntax to allow alpha variations like frecBlack/80
  frecWhite: "rgb(var(--color-White) / <alpha-value>)",
  frecBlack: "rgb(var(--color-Black) / <alpha-value>)",
  frecXXLightGray: "rgb(var(--color-XXLightGray) / <alpha-value>)",
  frecXLightGray: "rgb(var(--color-XLightGray) / <alpha-value>)",
  frecLightGray: "rgb(var(--color-LightGray) / <alpha-value>)",
  frecMidGray: "rgb(var(--color-MidGray) / <alpha-value>)",
  frecDarkGray: "rgb(var(--color-DarkGray) / <alpha-value>)",
  frecXDarkGray: "rgb(var(--color-XDarkGray) / <alpha-value>)",
};
