// Plaid won't give us the NSCC number needed for ACATS transfers, so we mantain our own
// list.
//
// https://www.notion.so/frec/Which-brokerages-do-we-want-to-support-for-the-initial-launch-0a64ea46e996430cb51214f1ebca6def
// https://dashboard.plaid.com/activity/status
// https://www.dtcc.com/-/media/Files/Downloads/client-center/NSCC/NSCC-MPID-Directory.xls
// https://docs.google.com/spreadsheets/d/1bY7H7MmngWDAHFHybJIQ0jqtLX5W0Hlxe08lfySJfPo/edit#gid=1423943050

import {
  primaryBrokerageList,
  SupportedBrokerage,
} from "./primaryBrokerageList";

export const fullBrokerageList: SupportedBrokerage[] = [
  {
    plaidInstitutionId: "ins_100190",
    nsccParticipantNo: "0075",
    name: "ADA Members Retirement Program - Participant",
  },
  {
    plaidInstitutionId: "ins_100282",
    nsccParticipantNo: "0235",
    name: "Acorns",
  },
  {
    plaidInstitutionId: "ins_100539",
    nsccParticipantNo: "0443",
    name: "American First Credit Union",
  },
  {
    plaidInstitutionId: "ins_100866",
    nsccParticipantNo: "0161",
    name: "Bank of America - Charitable Gift Fund",
  },
  {
    plaidInstitutionId: "ins_100872",
    nsccParticipantNo: "0075",
    name: "Bank of Arizona",
  },
  {
    plaidInstitutionId: "ins_100873",
    nsccParticipantNo: "0075",
    name: "Bank of Arkansas",
  },
  {
    plaidInstitutionId: "ins_100994",
    nsccParticipantNo: "0443",
    name: "Bank of Texas",
  },
  {
    plaidInstitutionId: "ins_100995",
    nsccParticipantNo: "0443",
    name: "Bank of Texas - Portfolio Online",
  },
  {
    plaidInstitutionId: "ins_101065",
    nsccParticipantNo: "0443",
    name: "Barclays Bank (US)",
  },
  {
    plaidInstitutionId: "ins_101207",
    nsccParticipantNo: "0141",
    name: "Boenning & Scattergood",
  },
  {
    plaidInstitutionId: "ins_101344",
    nsccParticipantNo: "0075",
    name: "CFCU Community Credit Union",
  },
  {
    plaidInstitutionId: "ins_102331",
    nsccParticipantNo: "0141",
    name: "Cresap Incorporated",
  },
  {
    plaidInstitutionId: "ins_102378",
    nsccParticipantNo: "0443",
    name: "DWS Investments - Shareholders Accounts",
  },
  {
    plaidInstitutionId: "ins_102463",
    nsccParticipantNo: "0075",
    name: "Diamond Credit Union",
  },
  {
    plaidInstitutionId: "ins_102628",
    nsccParticipantNo: "0057",
    name: "Edwards Lifesciences - Savings and Investment Plan",
  },
  {
    plaidInstitutionId: "ins_102801",
    nsccParticipantNo: "0725",
    name: "FS Investment Corporation",
  },
  {
    plaidInstitutionId: "ins_102908",
    nsccParticipantNo: "0235",
    name: "Federated Funds",
  },
  {
    plaidInstitutionId: "ins_102940",
    nsccParticipantNo: "0443",
    name: "Fieldpoint Private Bank & Trust",
  },
  {
    plaidInstitutionId: "ins_103085",
    nsccParticipantNo: "0141",
    name: "First Clearing - David A. Noyes & Company",
  },
  {
    plaidInstitutionId: "ins_103136",
    nsccParticipantNo: "0141",
    name: "First Dallas Securities",
  },
  {
    plaidInstitutionId: "ins_103467",
    nsccParticipantNo: "0226",
    name: "First State Bank and Trust Company (Nebraska)",
  },
  {
    plaidInstitutionId: "ins_103525",
    nsccParticipantNo: "0443",
    name: "First Bank of Nebraska - Personal",
  },
  {
    plaidInstitutionId: "ins_103880",
    nsccParticipantNo: "0226",
    name: "Flatwater Bank",
  },
  {
    plaidInstitutionId: "ins_103904",
    nsccParticipantNo: "0075",
    name: "Granite State Credit Union",
  },
  {
    plaidInstitutionId: "ins_104014",
    nsccParticipantNo: "0075",
    name: "HomeBank (MO)",
  },
  {
    plaidInstitutionId: "ins_104168",
    nsccParticipantNo: "0443",
    name: "Alight - CVS Health (Former CVS Health and Former MinuteClinic Colleagues)",
  },
  {
    plaidInstitutionId: "ins_104172",
    nsccParticipantNo: "0443",
    name: "Alight - AON Savings Plan",
  },
  {
    plaidInstitutionId: "ins_104173",
    nsccParticipantNo: "0443",
    name: "Alight - AT&T",
  },
  {
    plaidInstitutionId: "ins_104174",
    nsccParticipantNo: "0443",
    name: "Alight - Equitable",
  },
  {
    plaidInstitutionId: "ins_104175",
    nsccParticipantNo: "0443",
    name: "Alight - AbbVie",
  },
  {
    plaidInstitutionId: "ins_104176",
    nsccParticipantNo: "0443",
    name: "Alight - Abbott Laboratories",
  },
  {
    plaidInstitutionId: "ins_104178",
    nsccParticipantNo: "0443",
    name: "Alight - Accenture (Nonactive Participants and Spouses)",
  },
  {
    plaidInstitutionId: "ins_104183",
    nsccParticipantNo: "0443",
    name: "Alight - Allstate",
  },
  {
    plaidInstitutionId: "ins_104184",
    nsccParticipantNo: "0443",
    name: "Alight - Ally Benefits Service Center",
  },
  {
    plaidInstitutionId: "ins_104185",
    nsccParticipantNo: "0443",
    name: "Alight - American Family Insurance",
  },
  {
    plaidInstitutionId: "ins_104186",
    nsccParticipantNo: "0443",
    name: "Alight - American Red Cross",
  },
  {
    plaidInstitutionId: "ins_104188",
    nsccParticipantNo: "0443",
    name: "Alight - Autoliv",
  },
  {
    plaidInstitutionId: "ins_104193",
    nsccParticipantNo: "0443",
    name: "Alight - Baker Hughes",
  },
  {
    plaidInstitutionId: "ins_104195",
    nsccParticipantNo: "0443",
    name: "Alight - BP Benefits Center",
  },
  {
    plaidInstitutionId: "ins_104197",
    nsccParticipantNo: "0443",
    name: "Alight - CNA Insurance Companies",
  },
  {
    plaidInstitutionId: "ins_104199",
    nsccParticipantNo: "0443",
    name: "Alight - Caesars Entertainment",
  },
  {
    plaidInstitutionId: "ins_104200",
    nsccParticipantNo: "0443",
    name: "Alight - Campbells",
  },
  {
    plaidInstitutionId: "ins_104201",
    nsccParticipantNo: "0443",
    name: "Alight - Capital Group",
  },
  {
    plaidInstitutionId: "ins_104204",
    nsccParticipantNo: "0443",
    name: "Alight - CenterPoint Energy",
  },
  {
    plaidInstitutionId: "ins_104206",
    nsccParticipantNo: "0443",
    name: "Alight - Cintas",
  },
  {
    plaidInstitutionId: "ins_104207",
    nsccParticipantNo: "0443",
    name: "Alight - Citigroup Inc.",
  },
  {
    plaidInstitutionId: "ins_104208",
    nsccParticipantNo: "0443",
    name: "Alight - Conoco Phillips",
  },
  {
    plaidInstitutionId: "ins_104209",
    nsccParticipantNo: "0443",
    name: "Alight - Continental Airlines",
  },
  {
    plaidInstitutionId: "ins_104211",
    nsccParticipantNo: "0443",
    name: "Alight - DTE Energy",
  },
  {
    plaidInstitutionId: "ins_104212",
    nsccParticipantNo: "0443",
    name: "Alight - Dell",
  },
  {
    plaidInstitutionId: "ins_104213",
    nsccParticipantNo: "0443",
    name: "Alight - Deutsche Bank",
  },
  {
    plaidInstitutionId: "ins_104214",
    nsccParticipantNo: "0443",
    name: "Alight - Discover",
  },
  {
    plaidInstitutionId: "ins_104215",
    nsccParticipantNo: "0443",
    name: "Alight - Dole Food",
  },
  {
    plaidInstitutionId: "ins_104219",
    nsccParticipantNo: "0443",
    name: "Alight - Ernst & Young",
  },
  {
    plaidInstitutionId: "ins_104220",
    nsccParticipantNo: "0443",
    name: "Alight - Estee Lauder",
  },
  {
    plaidInstitutionId: "ins_104222",
    nsccParticipantNo: "0443",
    name: "Alight - Federal Reserve SmartBenefits",
  },
  {
    plaidInstitutionId: "ins_104226",
    nsccParticipantNo: "0443",
    name: "Alight - NXP (Freescale)",
  },
  {
    plaidInstitutionId: "ins_104227",
    nsccParticipantNo: "0443",
    name: "Alight - General Mills",
  },
  {
    plaidInstitutionId: "ins_104228",
    nsccParticipantNo: "0443",
    name: "Alight - Genuine Parts Company",
  },
  {
    plaidInstitutionId: "ins_104229",
    nsccParticipantNo: "0443",
    name: "Alight - Genworth Financial",
  },
  {
    plaidInstitutionId: "ins_104230",
    nsccParticipantNo: "0443",
    name: "Alight - State of Georgia",
  },
  {
    plaidInstitutionId: "ins_104231",
    nsccParticipantNo: "0443",
    name: "Alight - My HP Benefits",
  },
  {
    plaidInstitutionId: "ins_104234",
    nsccParticipantNo: "0443",
    name: "Alight - Henry Ford Health System",
  },
  {
    plaidInstitutionId: "ins_104235",
    nsccParticipantNo: "0443",
    name: "Alight - Hewitt",
  },
  {
    plaidInstitutionId: "ins_104237",
    nsccParticipantNo: "0443",
    name: "Alight - Home Depot",
  },
  {
    plaidInstitutionId: "ins_104238",
    nsccParticipantNo: "0443",
    name: "Alight - Honeywell",
  },
  {
    plaidInstitutionId: "ins_104240",
    nsccParticipantNo: "0443",
    name: "Alight - Huntington Ingalls Industries",
  },
  {
    plaidInstitutionId: "ins_104241",
    nsccParticipantNo: "0443",
    name: "Alight - IBM Canada Ltd.",
  },
  {
    plaidInstitutionId: "ins_104242",
    nsccParticipantNo: "0443",
    name: "Alight - Voya",
  },
  {
    plaidInstitutionId: "ins_104250",
    nsccParticipantNo: "0443",
    name: "Alight - Kohls Alumni",
  },
  {
    plaidInstitutionId: "ins_104252",
    nsccParticipantNo: "0443",
    name: "Alight - Land O'Lakes, Inc.",
  },
  {
    plaidInstitutionId: "ins_104256",
    nsccParticipantNo: "0443",
    name: "Alight - Mattel",
  },
  {
    plaidInstitutionId: "ins_104257",
    nsccParticipantNo: "0443",
    name: "Alight - McDonalds",
  },
  {
    plaidInstitutionId: "ins_104260",
    nsccParticipantNo: "0443",
    name: "Alight - MetLife",
  },
  {
    plaidInstitutionId: "ins_104264",
    nsccParticipantNo: "0443",
    name: "Alight - My Waste Management TotalRewards",
  },
  {
    plaidInstitutionId: "ins_104267",
    nsccParticipantNo: "0443",
    name: "Alight - New York Life",
  },
  {
    plaidInstitutionId: "ins_104269",
    nsccParticipantNo: "0443",
    name: "Alight - Nokia 401(k)",
  },
  {
    plaidInstitutionId: "ins_104270",
    nsccParticipantNo: "0443",
    name: "Alight - Northern Trust",
  },
  {
    plaidInstitutionId: "ins_104273",
    nsccParticipantNo: "0443",
    name: "Alight - Osram Sylvania",
  },
  {
    plaidInstitutionId: "ins_104278",
    nsccParticipantNo: "0443",
    name: "Alight - Packaging Corporation of America",
  },
  {
    plaidInstitutionId: "ins_104279",
    nsccParticipantNo: "0443",
    name: "Alight - Phillips 66",
  },
  {
    plaidInstitutionId: "ins_104281",
    nsccParticipantNo: "0443",
    name: "Alight - Prudential Benefits (Nonactive Participants)",
  },
  {
    plaidInstitutionId: "ins_104285",
    nsccParticipantNo: "0443",
    name: "Alight - Rolls Royce",
  },
  {
    plaidInstitutionId: "ins_104288",
    nsccParticipantNo: "0443",
    name: "Alight - Schlumberger",
  },
  {
    plaidInstitutionId: "ins_104289",
    nsccParticipantNo: "0443",
    name: "Alight - Sears (Shop Your Way)",
  },
  {
    plaidInstitutionId: "ins_104291",
    nsccParticipantNo: "0443",
    name: "Alight - Sharp HealthCare",
  },
  {
    plaidInstitutionId: "ins_104293",
    nsccParticipantNo: "0443",
    name: "Alight - Solvay",
  },
  {
    plaidInstitutionId: "ins_104295",
    nsccParticipantNo: "0443",
    name: "Alight - Sony",
  },
  {
    plaidInstitutionId: "ins_104298",
    nsccParticipantNo: "0443",
    name: "Alight - Starbucks",
  },
  {
    plaidInstitutionId: "ins_104303",
    nsccParticipantNo: "0443",
    name: "Alight - Target",
  },
  {
    plaidInstitutionId: "ins_104304",
    nsccParticipantNo: "0443",
    name: "Alight - TDS",
  },
  {
    plaidInstitutionId: "ins_104306",
    nsccParticipantNo: "0443",
    name: "Alight - UBS",
  },
  {
    plaidInstitutionId: "ins_104308",
    nsccParticipantNo: "0443",
    name: "Alight - US Bank",
  },
  {
    plaidInstitutionId: "ins_104310",
    nsccParticipantNo: "0443",
    name: "Alight - Unisys",
  },
  {
    plaidInstitutionId: "ins_104311",
    nsccParticipantNo: "0443",
    name: "Alight - United Airlines",
  },
  {
    plaidInstitutionId: "ins_104314",
    nsccParticipantNo: "0443",
    name: "Alight - Walgreens Hello Benefits",
  },
  {
    plaidInstitutionId: "ins_104315",
    nsccParticipantNo: "0443",
    name: "Alight - Wellington Management",
  },
  {
    plaidInstitutionId: "ins_104320",
    nsccParticipantNo: "0443",
    name: "Alight - WestRock",
  },
  {
    plaidInstitutionId: "ins_104322",
    nsccParticipantNo: "0443",
    name: "Alight - JCPenny Powerline",
  },
  {
    plaidInstitutionId: "ins_104623",
    nsccParticipantNo: "0161",
    name: "Invesco - CollegeBound 529",
  },
  {
    plaidInstitutionId: "ins_104637",
    nsccParticipantNo: "0701",
    name: "Investors Bank - Personal & Small Business",
  },
  {
    plaidInstitutionId: "ins_104746",
    nsccParticipantNo: "0226",
    name: "John Hancock New York Pensions",
  },
  {
    plaidInstitutionId: "ins_105124",
    nsccParticipantNo: "0226",
    name: "Lowell Five Cent Savings Bank",
  },
  {
    plaidInstitutionId: "ins_105346",
    nsccParticipantNo: "0075",
    name: "Members 1st FCU",
  },
  {
    plaidInstitutionId: "ins_105634",
    nsccParticipantNo: "0443",
    name: "Morningstar Managed Portfolios - Client Access",
  },
  {
    plaidInstitutionId: "ins_105869",
    nsccParticipantNo: "0188",
    name: "New England Pension",
  },
  {
    plaidInstitutionId: "ins_105888",
    nsccParticipantNo: "0062",
    name: "New York Life (Streetscape) - client access",
  },
  {
    plaidInstitutionId: "ins_105932",
    nsccParticipantNo: "0075",
    name: "Noble Davis Consulting",
  },
  {
    plaidInstitutionId: "ins_106699",
    nsccParticipantNo: "0141",
    name: "Prospera Financial Services",
  },
  {
    plaidInstitutionId: "ins_public",
    blocksPlaid: true,
    nsccParticipantNo: "0158",
    name: "Public",
  },
  {
    plaidInstitutionId: "ins_106894",
    nsccParticipantNo: "0141",
    name: "Register Financial Associates Inc - Client Access",
  },
  {
    plaidInstitutionId: "ins_107084",
    nsccParticipantNo: "0075",
    name: "SMART529 West Virginia College Savings Plan",
  },
  {
    plaidInstitutionId: "ins_107184",
    nsccParticipantNo: "0226",
    name: "Santander Consumer USA",
  },
  {
    plaidInstitutionId: "ins_107326",
    nsccParticipantNo: "0443",
    name: "Sentinel Benefits (SMARTLink) - Member Login",
  },
  {
    plaidInstitutionId: "ins_107731",
    nsccParticipantNo: "0443",
    name: "Summit Brokerage Services, Inc",
  },
  {
    plaidInstitutionId: "ins_108120",
    nsccParticipantNo: "0443",
    name: "The Oakmark Funds",
  },
  {
    plaidInstitutionId: "ins_108721",
    nsccParticipantNo: "0226",
    name: "AIG - Retirement Services",
  },
  {
    plaidInstitutionId: "ins_108899",
    nsccParticipantNo: "0161",
    name: "Walt Disney Shareholder Access",
  },
  {
    plaidInstitutionId: "ins_108955",
    nsccParticipantNo: "0103",
    name: "Wedbush Securities - ClientLink",
  },
  {
    plaidInstitutionId: "ins_109076",
    nsccParticipantNo: "0226",
    name: "William Blair & Company",
  },
  {
    plaidInstitutionId: "ins_109509",
    nsccParticipantNo: "0725",
    name: "First Gingham Credit Union",
  },
  {
    plaidInstitutionId: "ins_109816",
    nsccParticipantNo: "0443",
    name: "Consumers Credit Union (Michigan)",
  },
  {
    plaidInstitutionId: "ins_110225",
    nsccParticipantNo: "0443",
    name: "Alight - CSRA",
  },
  {
    plaidInstitutionId: "ins_110283",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - Motorola Solutions 401K Plan",
  },
  {
    plaidInstitutionId: "ins_110357",
    nsccParticipantNo: "0443",
    name: "State Employees Credit Union",
  },
  {
    plaidInstitutionId: "ins_110516",
    nsccParticipantNo: "0725",
    name: "ANB Bank",
  },
  {
    plaidInstitutionId: "ins_110521",
    nsccParticipantNo: "0075",
    name: "Erie Federal Credit Union",
  },
  {
    plaidInstitutionId: "ins_110785",
    nsccParticipantNo: "0443",
    name: "Arcadian Bank (Formerly Farmers State Bank of Hartland)",
  },
  {
    plaidInstitutionId: "ins_110859",
    nsccParticipantNo: "0443",
    name: "Alight - Arconic",
  },
  {
    plaidInstitutionId: "ins_111037",
    nsccParticipantNo: "8072",
    name: "Alpine Bank",
  },
  {
    plaidInstitutionId: "ins_111097",
    nsccParticipantNo: "0443",
    name: "American State Bank (TX)",
  },
  {
    plaidInstitutionId: "ins_111113",
    nsccParticipantNo: "0075",
    name: "Bank Midwest (MN)",
  },
  {
    plaidInstitutionId: "ins_111243",
    nsccParticipantNo: "0443",
    name: "First Bank & Trust",
  },
  {
    plaidInstitutionId: "ins_111620",
    nsccParticipantNo: "0443",
    name: "Farmers Trust & Savings Bank",
  },
  {
    plaidInstitutionId: "ins_113060",
    nsccParticipantNo: "0075",
    name: "Dow Chemical Employees Credit Union",
  },
  {
    plaidInstitutionId: "ins_114093",
    nsccParticipantNo: "0725",
    name: "First Financial Bank (AR) - Personal",
  },
  {
    plaidInstitutionId: "ins_114697",
    nsccParticipantNo: "0075",
    name: "FinalcialEdge Credit Union",
  },
  {
    plaidInstitutionId: "ins_115037",
    nsccParticipantNo: "0226",
    name: "NewBank",
  },
  {
    plaidInstitutionId: "ins_115413",
    nsccParticipantNo: "0075",
    name: "Civista Bank",
  },
  {
    plaidInstitutionId: "ins_115585",
    nsccParticipantNo: "0075",
    name: "Boeing Employees Credit Union (BECU) - Personal Online Banking",
  },
  {
    plaidInstitutionId: "ins_115604",
    nsccParticipantNo: "0756",
    name: "Ameriprise Financial - My Financial Accounts",
  },
  {
    plaidInstitutionId: "ins_115607",
    nsccParticipantNo: "0057",
    name: "Edward Jones - U.S. Clients Access",
  },
  {
    plaidInstitutionId: "ins_115614",
    nsccParticipantNo: "0158",
    name: "Stash",
  },
  {
    plaidInstitutionId: "ins_115689",
    nsccParticipantNo: "0075",
    name: "Blue Eagle Credit Union",
  },
  {
    plaidInstitutionId: "ins_115691",
    nsccParticipantNo: "0701",
    name: "FNB",
  },
  {
    plaidInstitutionId: "ins_115699",
    nsccParticipantNo: "0015",
    name: "Morgan Stanley Client Serv",
  },
  {
    plaidInstitutionId: "ins_115721",
    nsccParticipantNo: "0052",
    name: "Newbridge Securities - Client Access",
  },
  {
    plaidInstitutionId: "ins_115726",
    nsccParticipantNo: "0226",
    name: "Essex Financial Services",
  },
  {
    plaidInstitutionId: "ins_115728",
    nsccParticipantNo: "0443",
    name: "CUSO Financial Services, L.P",
  },
  {
    plaidInstitutionId: "ins_115730",
    nsccParticipantNo: "0052",
    name: "Trading Direct",
  },
  {
    plaidInstitutionId: "ins_115736",
    nsccParticipantNo: "0443",
    name: "Alight - Siemens",
  },
  {
    plaidInstitutionId: "ins_115738",
    nsccParticipantNo: "0052",
    name: "Cor Clearing (Retirement)",
  },
  {
    plaidInstitutionId: "ins_115741",
    nsccParticipantNo: "0443",
    name: "Searle & Co Securities",
  },
  {
    plaidInstitutionId: "ins_115752",
    nsccParticipantNo: "0443",
    name: "Alight - 3M",
  },
  {
    plaidInstitutionId: "ins_115753",
    nsccParticipantNo: "0443",
    name: "Alight - KBR",
  },
  {
    plaidInstitutionId: "ins_115756",
    nsccParticipantNo: "0443",
    name: "Alight - Ricoh",
  },
  {
    plaidInstitutionId: "ins_115757",
    nsccParticipantNo: "0443",
    name: "Alight - RR Donnelley",
  },
  {
    plaidInstitutionId: "ins_115758",
    nsccParticipantNo: "0443",
    name: "Alight - Sun Chemical Corp.",
  },
  {
    plaidInstitutionId: "ins_115761",
    nsccParticipantNo: "0443",
    name: "Alight - Westinghouse Electric Company",
  },
  {
    plaidInstitutionId: "ins_115776",
    nsccParticipantNo: "0443",
    name: "Alight - Exelon",
  },
  {
    plaidInstitutionId: "ins_115777",
    nsccParticipantNo: "0443",
    name: "Alight - Cummins",
  },
  {
    plaidInstitutionId: "ins_115778",
    nsccParticipantNo: "0443",
    name: "Alight - Koch",
  },
  {
    plaidInstitutionId: "ins_115779",
    nsccParticipantNo: "0443",
    name: "Alight - Sonoco Benefits",
  },
  {
    plaidInstitutionId: "ins_115782",
    nsccParticipantNo: "0443",
    name: "Alight - BAE Systems",
  },
  {
    plaidInstitutionId: "ins_115783",
    nsccParticipantNo: "0443",
    name: "Alight - Medtronic (For Non Employees)",
  },
  {
    plaidInstitutionId: "ins_115784",
    nsccParticipantNo: "0443",
    name: "Alight - Rite Aid Pharmacy",
  },
  {
    plaidInstitutionId: "ins_115785",
    nsccParticipantNo: "0443",
    name: "Alight - Reed Elsevier",
  },
  {
    plaidInstitutionId: "ins_115797",
    nsccParticipantNo: "0443",
    name: "Financial Benefits Credit Union",
  },
  {
    plaidInstitutionId: "ins_115815",
    nsccParticipantNo: "0141",
    name: "Wayne Hummer Investments",
  },
  {
    plaidInstitutionId: "ins_115816",
    nsccParticipantNo: "0141",
    name: "United Brokerage Services,Inc",
  },
  {
    plaidInstitutionId: "ins_115817",
    nsccParticipantNo: "0141",
    name: "Moors & Cabot - First Clearing",
  },
  {
    plaidInstitutionId: "ins_115818",
    nsccParticipantNo: "0141",
    name: "Rhodes Securities Inc",
  },
  {
    plaidInstitutionId: "ins_115822",
    nsccParticipantNo: "0443",
    name: "Farmers and Merchants State Bank of Appleton",
  },
  {
    plaidInstitutionId: "ins_115825",
    nsccParticipantNo: "0279",
    name: "Hilltop Securities",
  },
  {
    plaidInstitutionId: "ins_115827",
    nsccParticipantNo: "0443",
    name: "CUSO Home Lending - Borrower Secure Login",
  },
  {
    plaidInstitutionId: "ins_115839",
    nsccParticipantNo: "0443",
    name: "Lincoln Benefit Life (Annuity) - Client Access",
  },
  {
    plaidInstitutionId: "ins_115892",
    nsccParticipantNo: "0443",
    name: "Duncan Williams,Inc - Individual Client",
  },
  {
    plaidInstitutionId: "ins_115894",
    nsccParticipantNo: "0443",
    name: "Securities Research Inc",
  },
  {
    plaidInstitutionId: "ins_115895",
    nsccParticipantNo: "0443",
    name: "Ziegler Wealth Management",
  },
  {
    plaidInstitutionId: "ins_115898",
    nsccParticipantNo: "0188",
    name: "Nolan Financial",
  },
  {
    plaidInstitutionId: "ins_115899",
    nsccParticipantNo: "0443",
    name: "Cape Bank",
  },
  {
    plaidInstitutionId: "ins_115900",
    nsccParticipantNo: "0443",
    name: "Stone & YoungBerg",
  },
  {
    plaidInstitutionId: "ins_115934",
    nsccParticipantNo: "0443",
    name: "South Sound Bank - Cash Management",
  },
  {
    plaidInstitutionId: "ins_115944",
    nsccParticipantNo: "0443",
    name: "Charter One Investment Services",
  },
  {
    plaidInstitutionId: "ins_115947",
    nsccParticipantNo: "0443",
    name: "Streetscape - Jefferies",
  },
  {
    plaidInstitutionId: "ins_115953",
    nsccParticipantNo: "0235",
    name: "Joseph Gunnar & Co",
  },
  {
    plaidInstitutionId: "ins_115955",
    nsccParticipantNo: "0226",
    name: "Montauk Financial Group",
  },
  {
    plaidInstitutionId: "ins_115957",
    nsccParticipantNo: "0226",
    name: "McAdams Wright Ragen - Client Access",
  },
  {
    plaidInstitutionId: "ins_115959",
    nsccParticipantNo: "0226",
    name: "Gary Goldberg & Company - MyStreetscape",
  },
  {
    plaidInstitutionId: "ins_115963",
    nsccParticipantNo: "0189",
    name: "State Street Global Markets",
  },
  {
    plaidInstitutionId: "ins_115964",
    nsccParticipantNo: "0226",
    name: "Whitney Securities - Streetscape",
  },
  {
    plaidInstitutionId: "ins_115978",
    nsccParticipantNo: "0295",
    name: "Turning Point Associates Inc - Participant",
  },
  {
    plaidInstitutionId: "ins_115980",
    nsccParticipantNo: "0226",
    name: "TR Paul Inc - Participant",
  },
  {
    plaidInstitutionId: "ins_115987",
    nsccParticipantNo: "0235",
    name: "Sheakley - Client Access",
  },
  {
    plaidInstitutionId: "ins_116009",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - Archdiocese of New Orleans 401k Plan",
  },
  {
    plaidInstitutionId: "ins_116106",
    nsccParticipantNo: "0443",
    name: "Alight - Marriott Vacations Worldwide",
  },
  {
    plaidInstitutionId: "ins_116131",
    nsccParticipantNo: "0141",
    name: "Broker Dealer Financial Services Corp. - Client Account Access",
  },
  {
    plaidInstitutionId: "ins_116132",
    nsccParticipantNo: "0141",
    name: "Dorsey & Company Inc. - First Clearing",
  },
  {
    plaidInstitutionId: "ins_116135",
    nsccParticipantNo: "0141",
    name: "Trubee Collins",
  },
  {
    plaidInstitutionId: "ins_116146",
    nsccParticipantNo: "0226",
    name: "Solutia Benefit Center (Mercer)",
  },
  {
    plaidInstitutionId: "ins_116150",
    nsccParticipantNo: "0448",
    name: "Nuveen - Mutual Fund Access",
  },
  {
    plaidInstitutionId: "ins_116151",
    nsccParticipantNo: "0235",
    name: "Consolidated Financial Investments, Inc",
  },
  {
    plaidInstitutionId: "ins_116154",
    nsccParticipantNo: "0443",
    name: "Guggenheim Investments - Rydex Funds",
  },
  {
    plaidInstitutionId: "ins_116159",
    nsccParticipantNo: "0226",
    name: "AIC (formerly Carillon) - My Streetscape",
  },
  {
    plaidInstitutionId: "ins_116162",
    nsccParticipantNo: "0226",
    name: "LaSalle St Securities LLC - Streetscape",
  },
  {
    plaidInstitutionId: "ins_116224",
    nsccParticipantNo: "0443",
    name: "ENT Credit Union",
  },
  {
    plaidInstitutionId: "ins_116225",
    nsccParticipantNo: "0075",
    name: "Webster Bank",
  },
  {
    plaidInstitutionId: "ins_116268",
    nsccParticipantNo: "0075",
    name: "MidWest America Federal Credit Union",
  },
  {
    plaidInstitutionId: "ins_116278",
    nsccParticipantNo: "0161",
    name: "Merrill Lynch - Benefits",
  },
  {
    plaidInstitutionId: "ins_116297",
    nsccParticipantNo: "0075",
    name: "Solidarity Community FCU",
  },
  {
    plaidInstitutionId: "ins_116312",
    nsccParticipantNo: "0226",
    name: "Prudential Retirement",
  },
  {
    plaidInstitutionId: "ins_116317",
    nsccParticipantNo: "0756",
    name: "Actors FCU",
  },
  {
    plaidInstitutionId: "ins_116320",
    nsccParticipantNo: "0443",
    name: "Voya Retirement Plans",
  },
  {
    plaidInstitutionId: "ins_116322",
    nsccParticipantNo: "8072",
    name: "Alpine Credit Union",
  },
  {
    plaidInstitutionId: "ins_116335",
    nsccParticipantNo: "8199",
    name: "TradeStation - Client Center",
  },
  {
    plaidInstitutionId: "ins_116350",
    nsccParticipantNo: "0075",
    name: "North Coast Credit Union",
  },
  {
    plaidInstitutionId: "ins_116360",
    nsccParticipantNo: "0075",
    name: "MidWest America Federal Credit Union",
  },
  {
    plaidInstitutionId: "ins_116366",
    nsccParticipantNo: "0075",
    name: "DuPage Credit Union",
  },
  {
    plaidInstitutionId: "ins_116377",
    nsccParticipantNo: "0075",
    name: "Abri Credit Union",
  },
  {
    plaidInstitutionId: "ins_116414",
    nsccParticipantNo: "0443",
    name: "Key Investment Services, LLC",
  },
  {
    plaidInstitutionId: "ins_116415",
    nsccParticipantNo: "0443",
    name: "Empower Retirement (IRA) - IRA / IRA Rollover / Brokerage",
  },
  {
    plaidInstitutionId: "ins_116418",
    nsccParticipantNo: "0075",
    name: "Cadence Bank",
  },
  {
    plaidInstitutionId: "ins_116424",
    nsccParticipantNo: "0443",
    name: "American 1 Federal Credit Union",
  },
  {
    plaidInstitutionId: "ins_116435",
    nsccParticipantNo: "0443",
    name: "Ohio University Credit Union",
  },
  {
    plaidInstitutionId: "ins_116436",
    nsccParticipantNo: "0075",
    name: "Purdue Federal Credit Union",
  },
  {
    plaidInstitutionId: "ins_116441",
    nsccParticipantNo: "0443",
    name: "Call Federal Credit Union",
  },
  {
    plaidInstitutionId: "ins_116443",
    nsccParticipantNo: "0075",
    name: "Heritage Credit Union",
  },
  {
    plaidInstitutionId: "ins_116459",
    nsccParticipantNo: "0226",
    name: "Fort Lee Federal Credit Union",
  },
  {
    plaidInstitutionId: "ins_116468",
    nsccParticipantNo: "0188",
    name: "TD Auto Finance",
  },
  {
    plaidInstitutionId: "ins_116470",
    nsccParticipantNo: "0188",
    name: "TD Card Services",
  },
  {
    plaidInstitutionId: "ins_116481",
    nsccParticipantNo: "0443",
    name: "Complex Community FCU",
  },
  {
    plaidInstitutionId: "ins_116484",
    nsccParticipantNo: "0075",
    name: "Advancial Federal Credit Union",
  },
  {
    plaidInstitutionId: "ins_116492",
    nsccParticipantNo: "0443",
    name: "F&A Federal Credit Union",
  },
  {
    plaidInstitutionId: "ins_116496",
    nsccParticipantNo: "0235",
    name: "Carta (previously eShares)",
  },
  {
    plaidInstitutionId: "ins_116498",
    nsccParticipantNo: "0443",
    name: "T. Rowe Price",
  },
  {
    plaidInstitutionId: "ins_116501",
    nsccParticipantNo: "0226",
    name: "Principal Financial Group - Participant Logon",
  },
  {
    plaidInstitutionId: "ins_116508",
    nsccParticipantNo: "0075",
    name: "LPL Financial",
  },
  {
    plaidInstitutionId: "ins_116513",
    nsccParticipantNo: "0725",
    name: "Raymond James Client Access",
  },
  {
    plaidInstitutionId: "ins_116514",
    nsccParticipantNo: "0062",
    name: "New York's 529 College Savings Plan",
  },
  {
    plaidInstitutionId: "ins_116544",
    nsccParticipantNo: "0075",
    name: "UW Credit Union",
  },
  {
    plaidInstitutionId: "ins_116552",
    nsccParticipantNo: "0226",
    name: "Empower Retirement - Employer Retirement Plan",
  },
  {
    plaidInstitutionId: "ins_116557",
    nsccParticipantNo: "0226",
    name: "Slavic 401k - Participant-Investors",
  },
  {
    plaidInstitutionId: "ins_116560",
    nsccParticipantNo: "0443",
    name: "Securian Retirement Center",
  },
  {
    plaidInstitutionId: "ins_116575",
    nsccParticipantNo: "0443",
    name: "Alerus Retirement Solutions - Participant",
  },
  {
    plaidInstitutionId: "ins_116608",
    nsccParticipantNo: "0443",
    name: "Farmers and Merchants State Bank (NE)",
  },
  {
    plaidInstitutionId: "ins_116619",
    nsccParticipantNo: "0443",
    name: "Coastal Federal Credit Union",
  },
  {
    plaidInstitutionId: "ins_116632",
    nsccParticipantNo: "0701",
    name: "People's Bank of Mount Washington",
  },
  {
    plaidInstitutionId: "ins_116633",
    nsccParticipantNo: "0075",
    name: "Security State Bank (Wellington-Kansas)",
  },
  {
    plaidInstitutionId: "ins_116640",
    nsccParticipantNo: "0221",
    name: "Campbell Employees FCU - PCU login",
  },
  {
    plaidInstitutionId: "ins_116645",
    nsccParticipantNo: "0226",
    name: "National Life Group - Client Access",
  },
  {
    plaidInstitutionId: "ins_116652",
    nsccParticipantNo: "0075",
    name: "Dupont Community CU",
  },
  {
    plaidInstitutionId: "ins_116655",
    nsccParticipantNo: "0075",
    name: "Red Canoe Credit Union",
  },
  {
    plaidInstitutionId: "ins_116656",
    nsccParticipantNo: "0226",
    name: "Finger Lakes Federal Credit Union",
  },
  {
    plaidInstitutionId: "ins_116670",
    nsccParticipantNo: "0235",
    name: "Capital Bank of Texas (Carrizo Springs, Crystal City TX)",
  },
  {
    plaidInstitutionId: "ins_116695",
    nsccParticipantNo: "0443",
    name: "Alight - Duke Energy",
  },
  {
    plaidInstitutionId: "ins_116697",
    nsccParticipantNo: "0075",
    name: "South Texas FCU",
  },
  {
    plaidInstitutionId: "ins_116708",
    nsccParticipantNo: "0443",
    name: "Crane Federal Credit Union",
  },
  {
    plaidInstitutionId: "ins_116722",
    nsccParticipantNo: "0443",
    name: "Farmers & Merchants Bank (NE)",
  },
  {
    plaidInstitutionId: "ins_116723",
    nsccParticipantNo: "0443",
    name: "Insight Credit Union",
  },
  {
    plaidInstitutionId: "ins_116728",
    nsccParticipantNo: "0505",
    name: "Citi - Private Bank Investments",
  },
  {
    plaidInstitutionId: "ins_116734",
    nsccParticipantNo: "0701",
    name: "America's Credit Union (WA)",
  },
  {
    plaidInstitutionId: "ins_116736",
    nsccParticipantNo: "0443",
    name: "Farmers & Merchants State Bank (MN) - Online Banking",
  },
  {
    plaidInstitutionId: "ins_116737",
    nsccParticipantNo: "0075",
    name: "Vantage Credit Union",
  },
  {
    plaidInstitutionId: "ins_116739",
    nsccParticipantNo: "0226",
    name: "Fidelity - Charitable Gift Fund",
  },
  {
    plaidInstitutionId: "ins_116750",
    nsccParticipantNo: "0075",
    name: "HomeTrust Bank - Personal Banking",
  },
  {
    plaidInstitutionId: "ins_116758",
    nsccParticipantNo: "0443",
    name: "South Carolina Federal Credit Union",
  },
  {
    plaidInstitutionId: "ins_116770",
    nsccParticipantNo: "0075",
    name: "Canvas Credit Union",
  },
  {
    plaidInstitutionId: "ins_116780",
    nsccParticipantNo: "0005",
    name: "Goldman Sachs Private Wealth Management",
  },
  {
    plaidInstitutionId: "ins_116815",
    nsccParticipantNo: "0075",
    name: "1st Community Credit Union",
  },
  {
    plaidInstitutionId: "ins_116823",
    nsccParticipantNo: "0226",
    name: "Associated Bank - Personal",
  },
  {
    plaidInstitutionId: "ins_116862",
    nsccParticipantNo: "0052",
    name: "Axos Bank",
  },
  {
    plaidInstitutionId: "ins_116868",
    nsccParticipantNo: "0701",
    name: "GTE Financial",
  },
  {
    plaidInstitutionId: "ins_116875",
    nsccParticipantNo: "0443",
    name: "Alerus Financial",
  },
  {
    plaidInstitutionId: "ins_116876",
    nsccParticipantNo: "0075",
    name: "Cobalt Credit Union",
  },
  {
    plaidInstitutionId: "ins_116879",
    nsccParticipantNo: "0075",
    name: "Dean Bank",
  },
  {
    plaidInstitutionId: "ins_116886",
    nsccParticipantNo: "0443",
    name: "Somerset Trust Company",
  },
  {
    plaidInstitutionId: "ins_116889",
    nsccParticipantNo: "0701",
    name: "Greater Nevada Credit Union (Personal Banking)",
  },
  {
    plaidInstitutionId: "ins_116896",
    nsccParticipantNo: "0443",
    name: "Alight - UPMC Retirement Benefits",
  },
  {
    plaidInstitutionId: "ins_116898",
    nsccParticipantNo: "0443",
    name: "Bank of O'Fallon",
  },
  {
    plaidInstitutionId: "ins_116917",
    nsccParticipantNo: "0075",
    name: "Erie Community Credit Union",
  },
  {
    plaidInstitutionId: "ins_116921",
    nsccParticipantNo: "0161",
    name: "Invesco - Investor Account Access",
  },
  {
    plaidInstitutionId: "ins_116922",
    nsccParticipantNo: "0075",
    name: "Metro Medical Credit Union",
  },
  {
    plaidInstitutionId: "ins_116929",
    nsccParticipantNo: "0443",
    name: "River Bank & Trust",
  },
  {
    plaidInstitutionId: "ins_116938",
    nsccParticipantNo: "0443",
    name: "Alight - Meijer",
  },
  {
    plaidInstitutionId: "ins_116960",
    nsccParticipantNo: "1497",
    name: "M1 Finance",
  },
  {
    plaidInstitutionId: "ins_116962",
    nsccParticipantNo: "0443",
    name: "Pershing Investments - NetXInvestor",
  },
  {
    plaidInstitutionId: "ins_116964",
    nsccParticipantNo: "0226",
    name: "John Hancock - Retirement Plan Services",
  },
  {
    plaidInstitutionId: "ins_116969",
    nsccParticipantNo: "0443",
    name: "TIAA (Individual Investing)",
  },
  {
    plaidInstitutionId: "ins_116972",
    nsccParticipantNo: "0062",
    name: "Ascensus",
  },
  {
    plaidInstitutionId: "ins_116973",
    nsccParticipantNo: "0443",
    name: "OneAmerica - Account Services",
  },
  {
    plaidInstitutionId: "ins_116976",
    nsccParticipantNo: "5016",
    name: "Stifel Nicolaus",
  },
  {
    plaidInstitutionId: "ins_116981",
    nsccParticipantNo: "0443",
    name: "American Funds - Retirement Plan (Participant)",
  },
  {
    plaidInstitutionId: "ins_116995",
    nsccParticipantNo: "0158",
    name: "TastyWorks",
  },
  {
    plaidInstitutionId: "ins_116999",
    nsccParticipantNo: "0062",
    name: "My Vanguard Plan (Ascensus)",
  },
  {
    plaidInstitutionId: "ins_117022",
    nsccParticipantNo: "0141",
    name: "Countryside Bank",
  },
  {
    plaidInstitutionId: "ins_117030",
    nsccParticipantNo: "0443",
    name: "Alight - Adventist HealthCare Retirement Plan",
  },
  {
    plaidInstitutionId: "ins_117049",
    nsccParticipantNo: "0188",
    name: "Health Savings Administrators",
  },
  {
    plaidInstitutionId: "ins_117067",
    nsccParticipantNo: "0547",
    name: "Robert Baird Online",
  },
  {
    plaidInstitutionId: "ins_117078",
    nsccParticipantNo: "0443",
    name: "Alight - Morgan Stanley",
  },
  {
    plaidInstitutionId: "ins_117083",
    nsccParticipantNo: "0221",
    name: "UBS EquatePlus",
  },
  {
    plaidInstitutionId: "ins_117091",
    nsccParticipantNo: "0443",
    name: "South Metro Federal Credit Union",
  },
  {
    plaidInstitutionId: "ins_117098",
    nsccParticipantNo: "0443",
    name: "Santander Investment Services",
  },
  {
    plaidInstitutionId: "ins_117105",
    nsccParticipantNo: "0075",
    name: "Security National Financial Services -  iconnect2invest",
  },
  {
    plaidInstitutionId: "ins_117136",
    nsccParticipantNo: "0235",
    name: "City National Bank (CA) - Personal",
  },
  {
    plaidInstitutionId: "ins_117144",
    nsccParticipantNo: "0443",
    name: "Piedmont Advantage Credit Union",
  },
  {
    plaidInstitutionId: "ins_117145",
    nsccParticipantNo: "6742",
    name: "Eastern Bank (MA)",
  },
  {
    plaidInstitutionId: "ins_117185",
    nsccParticipantNo: "0075",
    name: "Credit Union of Denver",
  },
  {
    plaidInstitutionId: "ins_117186",
    nsccParticipantNo: "0075",
    name: "PSECU",
  },
  {
    plaidInstitutionId: "ins_117192",
    nsccParticipantNo: "0075",
    name: "Frandsen Bank & Trust - Business",
  },
  {
    plaidInstitutionId: "ins_117260",
    nsccParticipantNo: "0075",
    name: "Metro Federal Credit Union",
  },
  {
    plaidInstitutionId: "ins_117290",
    nsccParticipantNo: "0075",
    name: "Citizens State Bank (WI)",
  },
  {
    plaidInstitutionId: "ins_117296",
    nsccParticipantNo: "0443",
    name: "Alliance Bank (WI)",
  },
  {
    plaidInstitutionId: "ins_117298",
    nsccParticipantNo: "0443",
    name: "Alliance Credit Union (MO)",
  },
  {
    plaidInstitutionId: "ins_117302",
    nsccParticipantNo: "0443",
    name: "Alight - Eli Lilly and Company",
  },
  {
    plaidInstitutionId: "ins_117303",
    nsccParticipantNo: "0443",
    name: "Alight Solutions",
  },
  {
    plaidInstitutionId: "ins_117304",
    nsccParticipantNo: "0443",
    name: "Alight - United Technologies",
  },
  {
    plaidInstitutionId: "ins_117305",
    nsccParticipantNo: "0443",
    name: "Alight - P&G Retirement Plans",
  },
  {
    plaidInstitutionId: "ins_117310",
    nsccParticipantNo: "0443",
    name: "BOK Financial",
  },
  {
    plaidInstitutionId: "ins_117317",
    nsccParticipantNo: "0188",
    name: "TD eTreasury",
  },
  {
    plaidInstitutionId: "ins_117320",
    nsccParticipantNo: "0443",
    name: "BNY Mellon 401k",
  },
  {
    plaidInstitutionId: "ins_117336",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - Oregon Savings Growth Plan",
  },
  {
    plaidInstitutionId: "ins_117341",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - UPS 401k Savings Plan",
  },
  {
    plaidInstitutionId: "ins_117343",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - Baxter Savings Plans",
  },
  {
    plaidInstitutionId: "ins_117344",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - Minnesota State Retirement System",
  },
  {
    plaidInstitutionId: "ins_117345",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - Calpers Supplemental Income Plans",
  },
  {
    plaidInstitutionId: "ins_117346",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - PERS/OPSRP Individual Account Program",
  },
  {
    plaidInstitutionId: "ins_117347",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - Pella Corporation 401k",
  },
  {
    plaidInstitutionId: "ins_117348",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - The MYR Group 401k",
  },
  {
    plaidInstitutionId: "ins_117350",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - Aetna 401k",
  },
  {
    plaidInstitutionId: "ins_117351",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - Kohler Company 401k",
  },
  {
    plaidInstitutionId: "ins_117352",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - ABA Retirement Funds",
  },
  {
    plaidInstitutionId: "ins_117353",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - MasterCard Retirement Plan",
  },
  {
    plaidInstitutionId: "ins_117354",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - CIRS 401k",
  },
  {
    plaidInstitutionId: "ins_117356",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - SUPERVALU STAR 401k Plan",
  },
  {
    plaidInstitutionId: "ins_117358",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - Hunter Douglas Inc Savings 401k",
  },
  {
    plaidInstitutionId: "ins_117362",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - Nestle SmartSavings Program",
  },
  {
    plaidInstitutionId: "ins_117363",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - Boyd Gaming Corp 401k Plan and Trust",
  },
  {
    plaidInstitutionId: "ins_117364",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - State of Michigan 401k/457 Plan",
  },
  {
    plaidInstitutionId: "ins_117365",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - Sodexo 401k Savings Plan",
  },
  {
    plaidInstitutionId: "ins_117366",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - Spirit AeroSystems Retirement",
  },
  {
    plaidInstitutionId: "ins_117369",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - Indiana Public Retirement System",
  },
  {
    plaidInstitutionId: "ins_117370",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - CalSTRS Pension2",
  },
  {
    plaidInstitutionId: "ins_117371",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - DAIMLER Retirement Savings Plan",
  },
  {
    plaidInstitutionId: "ins_117373",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - United Launch Alliance 401k Savings and Retirement Plan",
  },
  {
    plaidInstitutionId: "ins_117374",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - Akin Gump Strauss Hauer & Feld LLP",
  },
  {
    plaidInstitutionId: "ins_117376",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - DaVita Retirement Savings Plan",
  },
  {
    plaidInstitutionId: "ins_117377",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - CenterPoint Energy Savings Plan",
  },
  {
    plaidInstitutionId: "ins_117378",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - Colorado PERA 401k/DC/457 Plans",
  },
  {
    plaidInstitutionId: "ins_117382",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - Publix 401k SMART Plan",
  },
  {
    plaidInstitutionId: "ins_117383",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - Lubrizol Profit Sharing and Savings Plan",
  },
  {
    plaidInstitutionId: "ins_117388",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - The Vencore 401k Plan",
  },
  {
    plaidInstitutionId: "ins_117389",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - Sunlife Savings and Advantage Plan",
  },
  {
    plaidInstitutionId: "ins_117390",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - Fluor Corporation Retirement Plans",
  },
  {
    plaidInstitutionId: "ins_117391",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - Baker Botts L.L.P. 401k and Savings Plan",
  },
  {
    plaidInstitutionId: "ins_117392",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - Hearst Benefits Wealth - Retirement Savings",
  },
  {
    plaidInstitutionId: "ins_117393",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - Alliance Bernstein 401k Plan",
  },
  {
    plaidInstitutionId: "ins_117394",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - Gibson, Dunn & Crutcher Retirement Plan",
  },
  {
    plaidInstitutionId: "ins_117395",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - Freudenberg-NOK General Partnership 401k Plan",
  },
  {
    plaidInstitutionId: "ins_117398",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - Best Buy Retirement Savings Plan",
  },
  {
    plaidInstitutionId: "ins_117399",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - City of Los Angeles DC Plan",
  },
  {
    plaidInstitutionId: "ins_117401",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - Ametek Retirement Plan",
  },
  {
    plaidInstitutionId: "ins_117402",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - Refinitiv Retirement Plan",
  },
  {
    plaidInstitutionId: "ins_117403",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - Occidental Petroleum Corporation",
  },
  {
    plaidInstitutionId: "ins_117404",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - Gwinnett County Retirement Plans",
  },
  {
    plaidInstitutionId: "ins_117405",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - Atos 401k Savings Plan",
  },
  {
    plaidInstitutionId: "ins_117406",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - Constellis 401k Plan",
  },
  {
    plaidInstitutionId: "ins_117407",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - Dollar General Retirement Plan",
  },
  {
    plaidInstitutionId: "ins_117409",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - Huber 401k Investment Program",
  },
  {
    plaidInstitutionId: "ins_117411",
    nsccParticipantNo: "0443",
    name: "Alight - Caterpillar Inc.",
  },
  {
    plaidInstitutionId: "ins_117425",
    nsccParticipantNo: "0443",
    name: "Empower Retirement - Nada",
  },
  {
    plaidInstitutionId: "ins_117426",
    nsccParticipantNo: "0443",
    name: "Empower Retirement - TD Ameritrade - 401k Plan",
  },
  {
    plaidInstitutionId: "ins_117427",
    nsccParticipantNo: "0443",
    name: "Empower Retirement - Montana Public Employee Retirement Administration",
  },
  {
    plaidInstitutionId: "ins_117429",
    nsccParticipantNo: "0443",
    name: "Empower Retirement - CCOERA",
  },
  {
    plaidInstitutionId: "ins_117434",
    nsccParticipantNo: "0443",
    name: "Empower Retirement - AEP 401k",
  },
  {
    plaidInstitutionId: "ins_117435",
    nsccParticipantNo: "0443",
    name: "Empower Retirement - Penn State Milton S. Hershey Medical Center",
  },
  {
    plaidInstitutionId: "ins_117436",
    nsccParticipantNo: "0443",
    name: "Empower Retirement - Hallmark",
  },
  {
    plaidInstitutionId: "ins_117438",
    nsccParticipantNo: "0443",
    name: "Empower Retirement - Chicago Public Schools",
  },
  {
    plaidInstitutionId: "ins_117439",
    nsccParticipantNo: "0443",
    name: "Empower Retirement - JPMorgan Asset Management",
  },
  {
    plaidInstitutionId: "ins_117440",
    nsccParticipantNo: "0443",
    name: "Empower Retirement - EMJAY Retirement Services",
  },
  {
    plaidInstitutionId: "ins_117442",
    nsccParticipantNo: "0443",
    name: "Empower Retirement - Wisconsin Deferred Compensation Program",
  },
  {
    plaidInstitutionId: "ins_117443",
    nsccParticipantNo: "0443",
    name: "Empower Retirement - RELX Group",
  },
  {
    plaidInstitutionId: "ins_117445",
    nsccParticipantNo: "0443",
    name: "Empower Retirement - Persi",
  },
  {
    plaidInstitutionId: "ins_117449",
    nsccParticipantNo: "0443",
    name: "Empower Retirement - South Carolina Deferred Compensation Program",
  },
  {
    plaidInstitutionId: "ins_117450",
    nsccParticipantNo: "0443",
    name: "Empower Retirement - Washington State Deferred Compensation Program",
  },
  {
    plaidInstitutionId: "ins_117455",
    nsccParticipantNo: "0443",
    name: "Empower Retirement - Walgreens",
  },
  {
    plaidInstitutionId: "ins_117456",
    nsccParticipantNo: "0443",
    name: "Empower Retirement - Fifth Third Bank Retirement Plan",
  },
  {
    plaidInstitutionId: "ins_117457",
    nsccParticipantNo: "0443",
    name: "Empower Retirement - State of New Hampshire 457b Public Employees DCP",
  },
  {
    plaidInstitutionId: "ins_117459",
    nsccParticipantNo: "0443",
    name: "Empower Retirement - Massachusetts Deferred Compensation SMART Plan",
  },
  {
    plaidInstitutionId: "ins_117462",
    nsccParticipantNo: "0443",
    name: "Empower Retirement - Lockheed Martin Savings",
  },
  {
    plaidInstitutionId: "ins_117463",
    nsccParticipantNo: "0443",
    name: "Empower Retirement - American Funds",
  },
  {
    plaidInstitutionId: "ins_117464",
    nsccParticipantNo: "0443",
    name: "Empower Retirement - TN Retire Ready",
  },
  {
    plaidInstitutionId: "ins_117465",
    nsccParticipantNo: "0443",
    name: "Empower Retirement - County of Orange Defined Contribution Program",
  },
  {
    plaidInstitutionId: "ins_117467",
    nsccParticipantNo: "0443",
    name: "Empower Retirement - SunTrust",
  },
  {
    plaidInstitutionId: "ins_117468",
    nsccParticipantNo: "0443",
    name: "Empower Retirement - WellSpan Health",
  },
  {
    plaidInstitutionId: "ins_117469",
    nsccParticipantNo: "0443",
    name: "Empower Retirement - County Of Los Angeles Defined Contribution Plans",
  },
  {
    plaidInstitutionId: "ins_117472",
    nsccParticipantNo: "0443",
    name: "Empower Retirement - Minnesota State Retirement System (MNDCP)",
  },
  {
    plaidInstitutionId: "ins_117473",
    nsccParticipantNo: "0443",
    name: "Empower Retirement - SoonerSave",
  },
  {
    plaidInstitutionId: "ins_117480",
    nsccParticipantNo: "0443",
    name: "Empower Retirement - Thrivent",
  },
  {
    plaidInstitutionId: "ins_117482",
    nsccParticipantNo: "0443",
    name: "Empower Retirement - MetLife",
  },
  {
    plaidInstitutionId: "ins_117483",
    nsccParticipantNo: "0443",
    name: "Empower Retirement - CFG",
  },
  {
    plaidInstitutionId: "ins_117484",
    nsccParticipantNo: "0443",
    name: "Empower Retirement - Sony USA",
  },
  {
    plaidInstitutionId: "ins_117485",
    nsccParticipantNo: "0443",
    name: "Empower Retirement - Vail Resorts",
  },
  {
    plaidInstitutionId: "ins_117489",
    nsccParticipantNo: "0443",
    name: "Empower Retirement - Nebraska Public Power District",
  },
  {
    plaidInstitutionId: "ins_117490",
    nsccParticipantNo: "0443",
    name: "Empower Retirement - Mississippi Deferred Compensation Plan",
  },
  {
    plaidInstitutionId: "ins_117496",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - Starwood Savings and Retirement Plans",
  },
  {
    plaidInstitutionId: "ins_117500",
    nsccParticipantNo: "0443",
    name: "Voya Annuity & Assets Contracts - Voya - Client Login",
  },
  {
    plaidInstitutionId: "ins_117501",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - NAPA Profit Sharing Plans",
  },
  {
    plaidInstitutionId: "ins_117504",
    nsccParticipantNo: "0573",
    name: "Deutsche Bank - Deutsche Bank Alex Brown - DBConnect",
  },
  {
    plaidInstitutionId: "ins_117506",
    nsccParticipantNo: "0443",
    name: "Jefferies - Private Client Services",
  },
  {
    plaidInstitutionId: "ins_117507",
    nsccParticipantNo: "0505",
    name: "Citi - International Financial Services",
  },
  {
    plaidInstitutionId: "ins_117529",
    nsccParticipantNo: "0235",
    name: "International Assets Advisory (IAA) - Client Access",
  },
  {
    plaidInstitutionId: "ins_117530",
    nsccParticipantNo: "0443",
    name: "Aintree Capital - Client Access",
  },
  {
    plaidInstitutionId: "ins_117540",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - Serco Inc. 401(k) Retirement Plan",
  },
  {
    plaidInstitutionId: "ins_117541",
    nsccParticipantNo: "0352",
    name: "Serco Inc. 401(k) Retirement Plan",
  },
  {
    plaidInstitutionId: "ins_117544",
    nsccParticipantNo: "0443",
    name: "A.R. Schmeidler and Company",
  },
  {
    plaidInstitutionId: "ins_117545",
    nsccParticipantNo: "0443",
    name: "Actinver Securities",
  },
  {
    plaidInstitutionId: "ins_117546",
    nsccParticipantNo: "0226",
    name: "Associated Securites Corporation",
  },
  {
    plaidInstitutionId: "ins_117551",
    nsccParticipantNo: "0630",
    name: "BNP Private Wealth Management",
  },
  {
    plaidInstitutionId: "ins_117552",
    nsccParticipantNo: "0443",
    name: "BNY Mellon Capital Markets",
  },
  {
    plaidInstitutionId: "ins_117555",
    nsccParticipantNo: "0443",
    name: "Bulltick Capital Markets",
  },
  {
    plaidInstitutionId: "ins_117556",
    nsccParticipantNo: "0443",
    name: "Cadaret Grant & Co.",
  },
  {
    plaidInstitutionId: "ins_117561",
    nsccParticipantNo: "0443",
    name: "CRI Securities",
  },
  {
    plaidInstitutionId: "ins_117562",
    nsccParticipantNo: "0443",
    name: "Dreyfus Lion",
  },
  {
    plaidInstitutionId: "ins_117566",
    nsccParticipantNo: "0443",
    name: "First Citizens Securities",
  },
  {
    plaidInstitutionId: "ins_117571",
    nsccParticipantNo: "0075",
    name: "Invest Financial Corporation",
  },
  {
    plaidInstitutionId: "ins_117578",
    nsccParticipantNo: "0443",
    name: "NetExchange Client",
  },
  {
    plaidInstitutionId: "ins_117581",
    nsccParticipantNo: "0443",
    name: "Personal Capital Pershing Login",
  },
  {
    plaidInstitutionId: "ins_117583",
    nsccParticipantNo: "0443",
    name: "RM Stark and Company",
  },
  {
    plaidInstitutionId: "ins_117585",
    nsccParticipantNo: "0443",
    name: "SECU Brokerage Services",
  },
  {
    plaidInstitutionId: "ins_117589",
    nsccParticipantNo: "0075",
    name: "SII Investments",
  },
  {
    plaidInstitutionId: "ins_117594",
    nsccParticipantNo: "0443",
    name: "Venecredit Securities",
  },
  {
    plaidInstitutionId: "ins_117599",
    nsccParticipantNo: "0052",
    name: "York Securities",
  },
  {
    plaidInstitutionId: "ins_117609",
    nsccParticipantNo: "0075",
    name: "Home Bank - Business",
  },
  {
    plaidInstitutionId: "ins_117723",
    nsccParticipantNo: "0075",
    name: "Salem Five - Personal",
  },
  {
    plaidInstitutionId: "ins_117738",
    nsccParticipantNo: "0141",
    name: "Wells Fargo - CEO - Treasury Reporting",
  },
  {
    plaidInstitutionId: "ins_117745",
    nsccParticipantNo: "0226",
    name: "Sunbelt Federal Credit Union",
  },
  {
    plaidInstitutionId: "ins_117771",
    nsccParticipantNo: "0075",
    name: "Alaska USA Federal Credit Union",
  },
  {
    plaidInstitutionId: "ins_118112",
    nsccParticipantNo: "0443",
    name: "Bank of Oklahoma",
  },
  {
    plaidInstitutionId: "ins_118231",
    nsccParticipantNo: "0443",
    name: "First Southern National Bank",
  },
  {
    plaidInstitutionId: "ins_118325",
    nsccParticipantNo: "0221",
    name: "UBS OneSource - Texas Instruments",
  },
  {
    plaidInstitutionId: "ins_118368",
    nsccParticipantNo: "0221",
    name: "UBS OneSource - AIG",
  },
  {
    plaidInstitutionId: "ins_118372",
    nsccParticipantNo: "0221",
    name: "UBS OneSource - Aetna",
  },
  {
    plaidInstitutionId: "ins_118391",
    nsccParticipantNo: "0443",
    name: "Bank of the West - Business - WebDirect",
  },
  {
    plaidInstitutionId: "ins_118395",
    nsccParticipantNo: "0226",
    name: "Fidelity NetBenefits",
  },
  {
    plaidInstitutionId: "ins_118398",
    nsccParticipantNo: "8072",
    name: "ALPS Federal Credit Union",
  },
  {
    plaidInstitutionId: "ins_118432",
    nsccParticipantNo: "0443",
    name: "BOK Financial - Asset Management (Participant)",
  },
  {
    plaidInstitutionId: "ins_118433",
    nsccParticipantNo: "0226",
    name: "SMS Retirement",
  },
  {
    plaidInstitutionId: "ins_118477",
    nsccParticipantNo: "0075",
    name: "Access Community Credit Union",
  },
  {
    plaidInstitutionId: "ins_118504",
    nsccParticipantNo: "0075",
    name: "Freedom First Credit Union - Personal",
  },
  {
    plaidInstitutionId: "ins_118518",
    nsccParticipantNo: "0595",
    name: "Vision Brokerage Services, LLC - MyStreetscape",
  },
  {
    plaidInstitutionId: "ins_118520",
    nsccParticipantNo: "0221",
    name: "UBIS Online Investing - MyStreetscape",
  },
  {
    plaidInstitutionId: "ins_118522",
    nsccParticipantNo: "0226",
    name: "Thrivent Investment Management Inc - MyStreetscape",
  },
  {
    plaidInstitutionId: "ins_118523",
    nsccParticipantNo: "0188",
    name: "TD Wealth Management Services Inc. - MyStreetscape",
  },
  {
    plaidInstitutionId: "ins_118525",
    nsccParticipantNo: "0226",
    name: "SunTrust - Securities",
  },
  {
    plaidInstitutionId: "ins_118526",
    nsccParticipantNo: "0226",
    name: "Barry Murphy and Company",
  },
  {
    plaidInstitutionId: "ins_118527",
    nsccParticipantNo: "0226",
    name: "Signature Securities - MyStreetscape",
  },
  {
    plaidInstitutionId: "ins_118529",
    nsccParticipantNo: "0226",
    name: "smithhayes - MyStreetscape",
  },
  {
    plaidInstitutionId: "ins_118530",
    nsccParticipantNo: "0226",
    name: "Sigma Financial - MyStreetscape",
  },
  {
    plaidInstitutionId: "ins_118531",
    nsccParticipantNo: "0226",
    name: "Shemano Group",
  },
  {
    plaidInstitutionId: "ins_118532",
    nsccParticipantNo: "0226",
    name: "Sunset Financial - MyStreetscape",
  },
  {
    plaidInstitutionId: "ins_118533",
    nsccParticipantNo: "0443",
    name: "Summit Equities - MyStreetscape",
  },
  {
    plaidInstitutionId: "ins_118534",
    nsccParticipantNo: "0443",
    name: "SignatorOne Access - MyStreetscape",
  },
  {
    plaidInstitutionId: "ins_118535",
    nsccParticipantNo: "0226",
    name: "Seidler Companies",
  },
  {
    plaidInstitutionId: "ins_118536",
    nsccParticipantNo: "0226",
    name: "UMB Scout Brokerage Services - MyStreetscape Login",
  },
  {
    plaidInstitutionId: "ins_118537",
    nsccParticipantNo: "0226",
    name: "Creative Financial Group, a division of Synovus Securities, Inc. - MyStreetscape",
  },
  {
    plaidInstitutionId: "ins_118538",
    nsccParticipantNo: "0226",
    name: "Prudential - MyStreetscape",
  },
  {
    plaidInstitutionId: "ins_118539",
    nsccParticipantNo: "0355",
    name: "Primetrade - MyStreetscape",
  },
  {
    plaidInstitutionId: "ins_118540",
    nsccParticipantNo: "0226",
    name: "Popular Securities - MyStreetscape",
  },
  {
    plaidInstitutionId: "ins_118541",
    nsccParticipantNo: "0226",
    name: "Purshe Kaplan Sterling Investments",
  },
  {
    plaidInstitutionId: "ins_118544",
    nsccParticipantNo: "0226",
    name: "Marquette Financial Group",
  },
  {
    plaidInstitutionId: "ins_118545",
    nsccParticipantNo: "0226",
    name: "Oberweis - MyStreetscape",
  },
  {
    plaidInstitutionId: "ins_118546",
    nsccParticipantNo: "0226",
    name: "Northern Trust Securities, Inc. - MyStreetscape",
  },
  {
    plaidInstitutionId: "ins_118547",
    nsccParticipantNo: "0226",
    name: "National Securities - MyStreetscape",
  },
  {
    plaidInstitutionId: "ins_118548",
    nsccParticipantNo: "0750",
    name: "Nelnet Capital LLC - MyStreetscape",
  },
  {
    plaidInstitutionId: "ins_118550",
    nsccParticipantNo: "0226",
    name: "Muriel Siebert Investments",
  },
  {
    plaidInstitutionId: "ins_118551",
    nsccParticipantNo: "0141",
    name: "Moors & Cabot - MyStreetscape",
  },
  {
    plaidInstitutionId: "ins_118555",
    nsccParticipantNo: "0443",
    name: "Mesirow Financial",
  },
  {
    plaidInstitutionId: "ins_118560",
    nsccParticipantNo: "0443",
    name: "Wealthscape - Kovack Securities",
  },
  {
    plaidInstitutionId: "ins_118566",
    nsccParticipantNo: "0226",
    name: "Huntington Online Investment Services - MyStreetscape",
  },
  {
    plaidInstitutionId: "ins_118567",
    nsccParticipantNo: "0443",
    name: "HighTower - MyStreetscape",
  },
  {
    plaidInstitutionId: "ins_118568",
    nsccParticipantNo: "0226",
    name: "Hancock Investment Services, Inc. - MyStreetscape",
  },
  {
    plaidInstitutionId: "ins_118570",
    nsccParticipantNo: "0443",
    name: "Park Avenue Advisory Services - MyStreetscape",
  },
  {
    plaidInstitutionId: "ins_118571",
    nsccParticipantNo: "2445",
    name: "First Horizon Advisors Inc. - MyStreetscape",
  },
  {
    plaidInstitutionId: "ins_118572",
    nsccParticipantNo: "0226",
    name: "First Georgetown - MyStreetscape",
  },
  {
    plaidInstitutionId: "ins_118574",
    nsccParticipantNo: "0226",
    name: "Chittenden Securities - MyStreetscape",
  },
  {
    plaidInstitutionId: "ins_118575",
    nsccParticipantNo: "0725",
    name: "Capital Securities Investment Corporation - MyStreetscape",
  },
  {
    plaidInstitutionId: "ins_118576",
    nsccParticipantNo: "0161",
    name: "Cowen and Company - MyStreetscape",
  },
  {
    plaidInstitutionId: "ins_118577",
    nsccParticipantNo: "0226",
    name: "Commerce Bank Brokerage",
  },
  {
    plaidInstitutionId: "ins_118578",
    nsccParticipantNo: "0279",
    name: "Colonial Brokerage - MyStreetscape",
  },
  {
    plaidInstitutionId: "ins_118579",
    nsccParticipantNo: "0226",
    name: "Collins Stewart, LLC. - MyStreetscape",
  },
  {
    plaidInstitutionId: "ins_118580",
    nsccParticipantNo: "0226",
    name: "Citizens Bank - Investment Services",
  },
  {
    plaidInstitutionId: "ins_118581",
    nsccParticipantNo: "0443",
    name: "Cambridge Investment Research - MyStreetscape",
  },
  {
    plaidInstitutionId: "ins_118586",
    nsccParticipantNo: "0226",
    name: "Ameritas Investments - Wealthscape Investor",
  },
  {
    plaidInstitutionId: "ins_118588",
    nsccParticipantNo: "0226",
    name: "Bankoh Investment Services - MyStreetscape",
  },
  {
    plaidInstitutionId: "ins_118589",
    nsccParticipantNo: "0226",
    name: "Barrett and Company - MyStreetscape",
  },
  {
    plaidInstitutionId: "ins_118592",
    nsccParticipantNo: "0443",
    name: "Womens Financial Network at Siebert",
  },
  {
    plaidInstitutionId: "ins_118594",
    nsccParticipantNo: "0226",
    name: "newalliance - MyStreetscape",
  },
  {
    plaidInstitutionId: "ins_118595",
    nsccParticipantNo: "0226",
    name: "Girard Securities - MyStreetscape",
  },
  {
    plaidInstitutionId: "ins_118596",
    nsccParticipantNo: "0235",
    name: "FISNonline Streetscape - MyStreetscape",
  },
  {
    plaidInstitutionId: "ins_118597",
    nsccParticipantNo: "0226",
    name: "Fifth Third Securities, Inc. - Wealthscape",
  },
  {
    plaidInstitutionId: "ins_118599",
    nsccParticipantNo: "0250",
    name: "FIG Partners",
  },
  {
    plaidInstitutionId: "ins_118604",
    nsccParticipantNo: "0141",
    name: "Wintrust - Wealth Management Investments",
  },
  {
    plaidInstitutionId: "ins_118612",
    nsccParticipantNo: "0226",
    name: "B Riley Wealth Management",
  },
  {
    plaidInstitutionId: "ins_118618",
    nsccParticipantNo: "0226",
    name: "Simmons First - Investment Group",
  },
  {
    plaidInstitutionId: "ins_118621",
    nsccParticipantNo: "0226",
    name: "B Riley",
  },
  {
    plaidInstitutionId: "ins_118623",
    nsccParticipantNo: "0141",
    name: "Brighton Securities",
  },
  {
    plaidInstitutionId: "ins_118625",
    nsccParticipantNo: "0141",
    name: "Coastal Equities",
  },
  {
    plaidInstitutionId: "ins_118626",
    nsccParticipantNo: "0250",
    name: "TradePMR",
  },
  {
    plaidInstitutionId: "ins_118629",
    nsccParticipantNo: "0226",
    name: "Wealthscape",
  },
  {
    plaidInstitutionId: "ins_118631",
    nsccParticipantNo: "0226",
    name: "SunTrust - MyStreetscape",
  },
  {
    plaidInstitutionId: "ins_118632",
    nsccParticipantNo: "0443",
    name: "Lincoln Financial Advisors - MyStreetscape",
  },
  {
    plaidInstitutionId: "ins_118633",
    nsccParticipantNo: "0226",
    name: "BMO Nesbitt Burns Securities Limited",
  },
  {
    plaidInstitutionId: "ins_118635",
    nsccParticipantNo: "0443",
    name: "Western International Securities",
  },
  {
    plaidInstitutionId: "ins_118636",
    nsccParticipantNo: "0226",
    name: "Wealthscape - Prudential",
  },
  {
    plaidInstitutionId: "ins_118637",
    nsccParticipantNo: "0226",
    name: "Kestra - MyWealthscape",
  },
  {
    plaidInstitutionId: "ins_118639",
    nsccParticipantNo: "0226",
    name: "Wealthscape Investor - Commonwealth",
  },
  {
    plaidInstitutionId: "ins_118641",
    nsccParticipantNo: "0226",
    name: "Gilder Gagnon Howe",
  },
  {
    plaidInstitutionId: "ins_118643",
    nsccParticipantNo: "0062",
    name: "Ascensus - Prudential",
  },
  {
    plaidInstitutionId: "ins_118644",
    nsccParticipantNo: "0062",
    name: "Ascensus - Mutual of Omaha",
  },
  {
    plaidInstitutionId: "ins_118645",
    nsccParticipantNo: "0062",
    name: "Ascensus - Merrill Lynch",
  },
  {
    plaidInstitutionId: "ins_118646",
    nsccParticipantNo: "0062",
    name: "Ascensus - Pacific Life",
  },
  {
    plaidInstitutionId: "ins_118647",
    nsccParticipantNo: "0062",
    name: "Ascensus - American Funds",
  },
  {
    plaidInstitutionId: "ins_118648",
    nsccParticipantNo: "0062",
    name: "Ascensus - BB&T",
  },
  {
    plaidInstitutionId: "ins_118649",
    nsccParticipantNo: "0158",
    name: "ShareBuilder 401k - ASCENSUS",
  },
  {
    plaidInstitutionId: "ins_119004",
    nsccParticipantNo: "0161",
    name: "Reliance Trust Company",
  },
  {
    plaidInstitutionId: "ins_119026",
    nsccParticipantNo: "0141",
    name: "Haverford Trust Company (Trust)",
  },
  {
    plaidInstitutionId: "ins_119036",
    nsccParticipantNo: "0141",
    name: "First Citizens Wealth Management",
  },
  {
    plaidInstitutionId: "ins_119072",
    nsccParticipantNo: "0701",
    name: "North Shore Investments & Trust",
  },
  {
    plaidInstitutionId: "ins_119080",
    nsccParticipantNo: "0279",
    name: "Independence Bank - Investment and Trust Accounts",
  },
  {
    plaidInstitutionId: "ins_119126",
    nsccParticipantNo: "0075",
    name: "Southeastern Trust Company",
  },
  {
    plaidInstitutionId: "ins_119216",
    nsccParticipantNo: "0443",
    name: "Pershing Investments - NetXInvestor",
  },
  {
    plaidInstitutionId: "ins_119287",
    nsccParticipantNo: "0075",
    name: "Comtrust Federal Credit Union",
  },
  {
    plaidInstitutionId: "ins_119355",
    nsccParticipantNo: "0443",
    name: "Voya - ADP TotalSource Retirement Savings Plan",
  },
  {
    plaidInstitutionId: "ins_119359",
    nsccParticipantNo: "0188",
    name: "TD Ameritrade - Retirement (Participant)",
  },
  {
    plaidInstitutionId: "ins_119386",
    nsccParticipantNo: "0443",
    name: "Empower - Intuit 401(K) Plan",
  },
  {
    plaidInstitutionId: "ins_119434",
    nsccParticipantNo: "0075",
    name: "Greylock Federal Credit Union",
  },
  {
    plaidInstitutionId: "ins_119462",
    nsccParticipantNo: "0443",
    name: "Advantage Credit Union (OH)",
  },
  {
    plaidInstitutionId: "ins_119573",
    nsccParticipantNo: "0158",
    name: "Ally Invest (TradeKing)",
  },
  {
    plaidInstitutionId: "ins_119653",
    nsccParticipantNo: "0075",
    name: "Deere Employees Credit Union",
  },
  {
    plaidInstitutionId: "ins_119704",
    nsccParticipantNo: "0062",
    name: "New York 529 College Savings Program",
  },
  {
    plaidInstitutionId: "ins_119798",
    nsccParticipantNo: "0443",
    name: "Voya Financial Partners (formerly ING)",
  },
  {
    plaidInstitutionId: "ins_119828",
    nsccParticipantNo: "0443",
    name: "T. Rowe Price Workplace Retirement - Tradelink",
  },
  {
    plaidInstitutionId: "ins_119837",
    nsccParticipantNo: "0226",
    name: "Prudential - Personal",
  },
  {
    plaidInstitutionId: "ins_119839",
    nsccParticipantNo: "0443",
    name: "Bank of Oklahoma (BOK Financial) - Client Point",
  },
  {
    plaidInstitutionId: "ins_119864",
    nsccParticipantNo: "0443",
    name: "Alight - Marriott",
  },
  {
    plaidInstitutionId: "ins_119888",
    nsccParticipantNo: "0443",
    name: "Arvest Central Mortgage",
  },
  {
    plaidInstitutionId: "ins_119911",
    nsccParticipantNo: "0443",
    name: "Personal Capital (Pershing Login)",
  },
  {
    plaidInstitutionId: "ins_119939",
    nsccParticipantNo: "0226",
    name: "Fidelity & Guaranty Life - Policyholder - Policyholder",
  },
  {
    plaidInstitutionId: "ins_119944",
    nsccParticipantNo: "0443",
    name: "Alight - S&P Global",
  },
  {
    plaidInstitutionId: "ins_119955",
    nsccParticipantNo: "0443",
    name: "Farmers Insurance Federal Credit Union",
  },
  {
    plaidInstitutionId: "ins_119958",
    nsccParticipantNo: "0075",
    name: "Solarity Credit Union",
  },
  {
    plaidInstitutionId: "ins_119964",
    nsccParticipantNo: "0443",
    name: "Alight - Nordstrom",
  },
  {
    plaidInstitutionId: "ins_119965",
    nsccParticipantNo: "0443",
    name: "Alight - GSK Benefits",
  },
  {
    plaidInstitutionId: "ins_119967",
    nsccParticipantNo: "0443",
    name: "Alight - Johnson & Johnson",
  },
  {
    plaidInstitutionId: "ins_119968",
    nsccParticipantNo: "0443",
    name: "Alight - Motorola Solutions",
  },
  {
    plaidInstitutionId: "ins_119970",
    nsccParticipantNo: "0443",
    name: "Alight - Goldman Sachs",
  },
  {
    plaidInstitutionId: "ins_119971",
    nsccParticipantNo: "0443",
    name: "Alight - Colgate",
  },
  {
    plaidInstitutionId: "ins_119972",
    nsccParticipantNo: "0443",
    name: "Alight - State Farm",
  },
  {
    plaidInstitutionId: "ins_119973",
    nsccParticipantNo: "0443",
    name: "Alight - Becton Dickinson and Company",
  },
  {
    plaidInstitutionId: "ins_119975",
    nsccParticipantNo: "0443",
    name: "Alight - American Express",
  },
  {
    plaidInstitutionId: "ins_119976",
    nsccParticipantNo: "0443",
    name: "Alight - Ameriprise Financial",
  },
  {
    plaidInstitutionId: "ins_119977",
    nsccParticipantNo: "0443",
    name: "Alight - KeyCorp",
  },
  {
    plaidInstitutionId: "ins_119978",
    nsccParticipantNo: "0443",
    name: "Alight - Siemens Gamesa",
  },
  {
    plaidInstitutionId: "ins_119980",
    nsccParticipantNo: "0062",
    name: "New York State Teachers Retirement System",
  },
  {
    plaidInstitutionId: "ins_119990",
    nsccParticipantNo: "0226",
    name: "Brinker Capital",
  },
  {
    plaidInstitutionId: "ins_120150",
    nsccParticipantNo: "0226",
    name: "Neuberger Berman - Mutual Fund Access",
  },
  {
    plaidInstitutionId: "ins_120171",
    nsccParticipantNo: "0443",
    name: "Farmers Bank of Willards",
  },
  {
    plaidInstitutionId: "ins_120180",
    nsccParticipantNo: "0164",
    name: "Schwab Charitable",
  },
  {
    plaidInstitutionId: "ins_120184",
    nsccParticipantNo: "0188",
    name: "TD Ameritrade Advisor Client",
  },
  {
    plaidInstitutionId: "ins_120188",
    nsccParticipantNo: "0158",
    name: "Firstrade",
  },
  {
    plaidInstitutionId: "ins_120257",
    nsccParticipantNo: "0158",
    name: "Titanvest",
  },
  {
    plaidInstitutionId: "ins_120264",
    nsccParticipantNo: "0226",
    name: "John Hancock - Investments",
  },
  {
    plaidInstitutionId: "ins_120296",
    nsccParticipantNo: "0158",
    name: "Apex Clearing",
  },
  {
    plaidInstitutionId: "ins_120298",
    nsccParticipantNo: "0188",
    name: "TD Ameritrade - ThinkOrSwim",
  },
  {
    plaidInstitutionId: "ins_120655",
    nsccParticipantNo: "0075",
    name: "First Kentucky Bank",
  },
  {
    plaidInstitutionId: "ins_120730",
    nsccParticipantNo: "0075",
    name: "Heritage Family Credit Union (VT)",
  },
  {
    plaidInstitutionId: "ins_121225",
    nsccParticipantNo: "0534",
    name: "Southern Security Federal Credit Union",
  },
  {
    plaidInstitutionId: "ins_121228",
    nsccParticipantNo: "0075",
    name: "CresCom Bank - Business",
  },
  {
    plaidInstitutionId: "ins_121257",
    nsccParticipantNo: "0443",
    name: "BBVA Compass Investment Solutions",
  },
  {
    plaidInstitutionId: "ins_121400",
    nsccParticipantNo: "0164",
    name: "Charles Schwab - Retirement Plan Center",
  },
  {
    plaidInstitutionId: "ins_121409",
    nsccParticipantNo: "0226",
    name: "Neuberger Berman - Pvt Asset Mgmt and Trst Svcs",
  },
  {
    plaidInstitutionId: "ins_121410",
    nsccParticipantNo: "0189",
    name: "New Mexico Educational Retirement Board",
  },
  {
    plaidInstitutionId: "ins_121417",
    nsccParticipantNo: "0443",
    name: "Ball State Federal Credit Union",
  },
  {
    plaidInstitutionId: "ins_121427",
    nsccParticipantNo: "0443",
    name: "Voya Financial - Voya Services Company",
  },
  {
    plaidInstitutionId: "ins_121630",
    nsccParticipantNo: "0075",
    name: "Interra Credit Union",
  },
  {
    plaidInstitutionId: "ins_121816",
    nsccParticipantNo: "0756",
    name: "Centennial Bank",
  },
  {
    plaidInstitutionId: "ins_122486",
    nsccParticipantNo: "0075",
    name: "First South Financial Credit Union",
  },
  {
    plaidInstitutionId: "ins_122529",
    nsccParticipantNo: "0075",
    name: "Central Bank (MO) - Business",
  },
  {
    plaidInstitutionId: "ins_122743",
    nsccParticipantNo: "0756",
    name: "First United Bank (KY)",
  },
  {
    plaidInstitutionId: "ins_122745",
    nsccParticipantNo: "0443",
    name: "Mutual Security Credit Union",
  },
  {
    plaidInstitutionId: "ins_123325",
    nsccParticipantNo: "0226",
    name: "Princeville State Bank",
  },
  {
    plaidInstitutionId: "ins_123616",
    nsccParticipantNo: "0443",
    name: "Comerica Securities",
  },
  {
    plaidInstitutionId: "ins_123630",
    nsccParticipantNo: "0443",
    name: "Key Investment Services",
  },
  {
    plaidInstitutionId: "ins_123652",
    nsccParticipantNo: "0226",
    name: "Seattle Credit Union",
  },
  {
    plaidInstitutionId: "ins_123672",
    nsccParticipantNo: "0750",
    name: "AssetMark eWealthmanager",
  },
  {
    plaidInstitutionId: "ins_123700",
    nsccParticipantNo: "0725",
    name: "American National Bank and Trust (TX) - Personal",
  },
  {
    plaidInstitutionId: "ins_123709",
    nsccParticipantNo: "0443",
    name: "Transamerica - MyTransamerica",
  },
  {
    plaidInstitutionId: "ins_123710",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - City of Los Angeles",
  },
  {
    plaidInstitutionId: "ins_123735",
    nsccParticipantNo: "0443",
    name: "Empower Retirement (NetxInvestor)",
  },
  {
    plaidInstitutionId: "ins_123762",
    nsccParticipantNo: "0443",
    name: "Park Avenue Securities",
  },
  {
    plaidInstitutionId: "ins_124000",
    nsccParticipantNo: "0443",
    name: "Securian Financial Services, Inc. - Brokerage Accounts - NetXInvestor",
  },
  {
    plaidInstitutionId: "ins_124106",
    nsccParticipantNo: "0075",
    name: "Home Bank SB",
  },
  {
    plaidInstitutionId: "ins_124141",
    nsccParticipantNo: "0075",
    name: "Cherokee State Bank (IA)",
  },
  {
    plaidInstitutionId: "ins_124234",
    nsccParticipantNo: "0443",
    name: "Benjamin F. Edwards & Co.",
  },
  {
    plaidInstitutionId: "ins_124514",
    nsccParticipantNo: "0062",
    name: "New York State Deferred Compensation Plan",
  },
  {
    plaidInstitutionId: "ins_125218",
    nsccParticipantNo: "0443",
    name: "Farmers State Bank of Alto Pass",
  },
  {
    plaidInstitutionId: "ins_125285",
    nsccParticipantNo: "0226",
    name: "Bryn Mawr Trust - (BMT)",
  },
  {
    plaidInstitutionId: "ins_125384",
    nsccParticipantNo: "0075",
    name: "American Bank & Trust",
  },
  {
    plaidInstitutionId: "ins_125463",
    nsccParticipantNo: "0443",
    name: "Gold Coast FCU (FL)",
  },
  {
    plaidInstitutionId: "ins_125501",
    nsccParticipantNo: "0443",
    name: "Voya Financial - Thomson Reuters",
  },
  {
    plaidInstitutionId: "ins_125502",
    nsccParticipantNo: "0226",
    name: "Prudential - Annuities (Investor)",
  },
  {
    plaidInstitutionId: "ins_125563",
    nsccParticipantNo: "5016",
    name: "Stifel Trust Company - Client Point",
  },
  {
    plaidInstitutionId: "ins_125862",
    nsccParticipantNo: "0075",
    name: "Boeing Helicopter CU",
  },
  {
    plaidInstitutionId: "ins_125881",
    nsccParticipantNo: "0075",
    name: "Pearl Hawaii Federal Credit Union",
  },
  {
    plaidInstitutionId: "ins_125924",
    nsccParticipantNo: "0443",
    name: "First Citizens Bank (Iowa)",
  },
  {
    plaidInstitutionId: "ins_125959",
    nsccParticipantNo: "0443",
    name: "Smackover State Bank",
  },
  {
    plaidInstitutionId: "ins_126109",
    nsccParticipantNo: "0443",
    name: "Alight - AIG",
  },
  {
    plaidInstitutionId: "ins_126110",
    nsccParticipantNo: "0141",
    name: "First Clearing",
  },
  {
    plaidInstitutionId: "ins_126165",
    nsccParticipantNo: "0052",
    name: "Axos - Liberty Login",
  },
  {
    plaidInstitutionId: "ins_126166",
    nsccParticipantNo: "0385",
    name: "E*TRADE Advisor Services - 401k",
  },
  {
    plaidInstitutionId: "ins_126268",
    nsccParticipantNo: "0015",
    name: "Solium Shareworks by Morgan Stanley",
  },
  {
    plaidInstitutionId: "ins_126339",
    nsccParticipantNo: "0158",
    name: "SoFi",
  },
  {
    plaidInstitutionId: "ins_126442",
    nsccParticipantNo: "0443",
    name: "Saturna Brokerage Services",
  },
  {
    plaidInstitutionId: "ins_127024",
    nsccParticipantNo: "0161",
    name: "Invesco - Retirement Plan Sponsor - Sponsor",
  },
  {
    plaidInstitutionId: "ins_127082",
    nsccParticipantNo: "0443",
    name: "FSC Securities Corporation",
  },
  {
    plaidInstitutionId: "ins_127155",
    nsccParticipantNo: "0141",
    name: "United Bank  - Wealth Management Access",
  },
  {
    plaidInstitutionId: "ins_127372",
    nsccParticipantNo: "0226",
    name: "SagePoint Financial",
  },
  {
    plaidInstitutionId: "ins_127437",
    nsccParticipantNo: "0158",
    name: "Shareholders Service Group",
  },
  {
    plaidInstitutionId: "ins_127465",
    nsccParticipantNo: "0443",
    name: "Hefren-Tillotson",
  },
  {
    plaidInstitutionId: "ins_127510",
    nsccParticipantNo: "0443",
    name: "First Command Financial Planning",
  },
  {
    plaidInstitutionId: "ins_127525",
    nsccParticipantNo: "0443",
    name: "Alight - Nokia 401(k)",
  },
  {
    plaidInstitutionId: "ins_127539",
    nsccParticipantNo: "0443",
    name: "Sentinel Securities",
  },
  {
    plaidInstitutionId: "ins_127567",
    nsccParticipantNo: "0443",
    name: "Stephens Inc",
  },
  {
    plaidInstitutionId: "ins_127583",
    nsccParticipantNo: "0443",
    name: "Oriental Financial Services",
  },
  {
    plaidInstitutionId: "ins_127590",
    nsccParticipantNo: "0443",
    name: "Beech Hill Securities",
  },
  {
    plaidInstitutionId: "ins_127604",
    nsccParticipantNo: "0443",
    name: "Woodbury Financial",
  },
  {
    plaidInstitutionId: "ins_127609",
    nsccParticipantNo: "0443",
    name: "Dreyfus Lion",
  },
  {
    plaidInstitutionId: "ins_127614",
    nsccParticipantNo: "0443",
    name: "Constellation Wealth Advisors LLC - Client Access",
  },
  {
    plaidInstitutionId: "ins_127624",
    nsccParticipantNo: "0141",
    name: "Hennion & Walsh",
  },
  {
    plaidInstitutionId: "ins_127635",
    nsccParticipantNo: "0443",
    name: "American Portfolios - Pershing",
  },
  {
    plaidInstitutionId: "ins_127638",
    nsccParticipantNo: "0226",
    name: "Santander Securities",
  },
  {
    plaidInstitutionId: "ins_127664",
    nsccParticipantNo: "0443",
    name: "CUSO Financial Services, L.P.",
  },
  {
    plaidInstitutionId: "ins_127672",
    nsccParticipantNo: "0443",
    name: "The Investment Center",
  },
  {
    plaidInstitutionId: "ins_127808",
    nsccParticipantNo: "0443",
    name: "Lincoln Investment Planning",
  },
  {
    plaidInstitutionId: "ins_127907",
    nsccParticipantNo: "0443",
    name: "Alight - Howmet",
  },
  {
    plaidInstitutionId: "ins_127972",
    nsccParticipantNo: "0701",
    name: "Orrstown Bank",
  },
  {
    plaidInstitutionId: "ins_127990",
    nsccParticipantNo: "0280",
    name: "U.S. Bank",
  },
  {
    plaidInstitutionId: "ins_128060",
    nsccParticipantNo: "0756",
    name: "Fort Worth City Credit Union",
  },
  {
    plaidInstitutionId: "ins_128747",
    nsccParticipantNo: "0057",
    name: "Edward Jones",
  },
  {
    plaidInstitutionId: "ins_128749",
    nsccParticipantNo: "0188",
    name: "New England Pension Plan Systems",
  },
  {
    plaidInstitutionId: "ins_128751",
    nsccParticipantNo: "0295",
    name: "Turning Point Associates",
  },
  {
    plaidInstitutionId: "ins_128792",
    nsccParticipantNo: "0295",
    name: "Turning Point Associates",
  },
  {
    plaidInstitutionId: "ins_129367",
    nsccParticipantNo: "0443",
    name: "Wiley Bros",
  },
  {
    plaidInstitutionId: "ins_129401",
    nsccParticipantNo: "0443",
    name: "Lantern Investments",
  },
  {
    plaidInstitutionId: "ins_129549",
    nsccParticipantNo: "0443",
    name: "Alight - EFE (Edelman Financial Engines)",
  },
  {
    plaidInstitutionId: "ins_129572",
    nsccParticipantNo: "0443",
    name: "Empower Retirement - Southwest Airlines",
  },
  {
    plaidInstitutionId: "ins_129583",
    nsccParticipantNo: "0443",
    name: "Empower Retirement - Baker Hughes",
  },
  {
    plaidInstitutionId: "ins_129604",
    nsccParticipantNo: "0725",
    name: "American National Bank and Trust (TX) - Business",
  },
  {
    plaidInstitutionId: "ins_129627",
    nsccParticipantNo: "0015",
    name: "Solium Shareworks - Global Stock and Rewards",
  },
  {
    plaidInstitutionId: "ins_129666",
    nsccParticipantNo: "2445",
    name: "First Horizon Bank - Business",
  },
  {
    plaidInstitutionId: "ins_129733",
    nsccParticipantNo: "0075",
    name: "Deere Community Credit Union",
  },
  {
    plaidInstitutionId: "ins_129816",
    nsccParticipantNo: "0226",
    name: "Credit Suisse Private Bank",
  },
  {
    plaidInstitutionId: "ins_129836",
    nsccParticipantNo: "0141",
    name: "St. Germain Investment Management",
  },
  {
    plaidInstitutionId: "ins_129983",
    nsccParticipantNo: "0443",
    name: "Bolton Global Capital",
  },
  {
    plaidInstitutionId: "ins_129989",
    nsccParticipantNo: "0443",
    name: "Commonwealth Securities Ltd",
  },
  {
    plaidInstitutionId: "ins_13",
    nsccParticipantNo: "0226",
    name: "PNC",
  },
  {
    plaidInstitutionId: "ins_130078",
    nsccParticipantNo: "0443",
    name: "Henley and Company",
  },
  {
    plaidInstitutionId: "ins_130121",
    nsccParticipantNo: "0443",
    name: "Cadaret Grant & Co",
  },
  {
    plaidInstitutionId: "ins_130123",
    nsccParticipantNo: "0443",
    name: "NetExchange Investor",
  },
  {
    plaidInstitutionId: "ins_130134",
    nsccParticipantNo: "0443",
    name: "Capital Analysts Incorporated - Pershing",
  },
  {
    plaidInstitutionId: "ins_130152",
    nsccParticipantNo: "0443",
    name: "CAPTRUST",
  },
  {
    plaidInstitutionId: "ins_130186",
    nsccParticipantNo: "0443",
    name: "M Holdings Securities",
  },
  {
    plaidInstitutionId: "ins_130209",
    nsccParticipantNo: "0141",
    name: "5th Bank of Eastern Fargo",
  },
  {
    plaidInstitutionId: "ins_130237",
    nsccParticipantNo: "0443",
    name: "BancWest Investment Services",
  },
  {
    plaidInstitutionId: "ins_130325",
    nsccParticipantNo: "0443",
    name: "Avon Products Employees Credit Union",
  },
  {
    plaidInstitutionId: "ins_130341",
    nsccParticipantNo: "0141",
    name: "D.M. Kelly & Company",
  },
  {
    plaidInstitutionId: "ins_130403",
    nsccParticipantNo: "0443",
    name: "Cuna Mutual Group products",
  },
  {
    plaidInstitutionId: "ins_130430",
    nsccParticipantNo: "0725",
    name: "Raymond James",
  },
  {
    plaidInstitutionId: "ins_130974",
    nsccParticipantNo: "0443",
    name: "Voya Retirement - Xerox",
  },
  {
    plaidInstitutionId: "ins_130993",
    nsccParticipantNo: "0443",
    name: "Alight Financial Solutions - NetXInvestor",
  },
  {
    plaidInstitutionId: "ins_130996",
    nsccParticipantNo: "0443",
    name: "Benjamin Edwards - NetXInvestor",
  },
  {
    plaidInstitutionId: "ins_131814",
    nsccParticipantNo: "0161",
    name: "Merrill Lynch - Benefits OnLine",
  },
  {
    plaidInstitutionId: "ins_115611",
    nsccParticipantNo: "0671",
    name: "Merrill Lynch",
  },
  {
    plaidInstitutionId: "ins_131862",
    nsccParticipantNo: "0226",
    name: "A+ Federal Credit Union",
  },
  {
    plaidInstitutionId: "ins_131928",
    nsccParticipantNo: "0443",
    name: "Nationwide Securities, LLC",
  },
  {
    plaidInstitutionId: "ins_131929",
    nsccParticipantNo: "0188",
    name: "TD Wealth - NetXInvestor",
  },
  {
    plaidInstitutionId: "ins_131965",
    nsccParticipantNo: "0443",
    name: "BNY Mellon-Pershing Investor",
  },
  {
    plaidInstitutionId: "ins_131966",
    nsccParticipantNo: "0443",
    name: "Arvest - Wealth Management",
  },
  {
    plaidInstitutionId: "ins_131967",
    nsccParticipantNo: "0443",
    name: "Capital Growth, Inc",
  },
  {
    plaidInstitutionId: "ins_132707",
    nsccParticipantNo: "0443",
    name: "Empower Retirement - Wells Fargo",
  },
  {
    plaidInstitutionId: "ins_132712",
    nsccParticipantNo: "0443",
    name: "Alight - Raytheon Technologies",
  },
  {
    plaidInstitutionId: "ins_15",
    nsccParticipantNo: "2402",
    name: "Navy Federal Credit Union",
  },
  {
    plaidInstitutionId: "ins_4",
    nsccParticipantNo: "0141",
    name: "Wells Fargo",
  },
  {
    plaidInstitutionId: "ins_127991",
    nsccParticipantNo: "0141",
    name: "Wells Fargo Oauth",
  },
  {
    plaidInstitutionId: "ins_5",
    nsccParticipantNo: "0908",
    name: "Citibank Online",
  },
  {
    plaidInstitutionId: "ins_52",
    nsccParticipantNo: "0158",
    name: "Marcus by Goldman Sachs",
  },
  {
    plaidInstitutionId: "ins_117958",
    nsccParticipantNo: "0188",
    name: "TD Ameritrade",
    logo: "/images/institutions/td-ameritrade.png",
  },
  {
    plaidInstitutionId: "ins_webull",
    blocksPlaid: true,
    nsccParticipantNo: "0158",
    name: "Webull",
  },
  {
    plaidInstitutionId: "ins_jpm_chase",
    blocksPlaid: true,
    nsccParticipantNo: "0902",
    name: "JPMorgan Chase",
  },
  {
    plaidInstitutionId: "ins_altruist",
    blocksPlaid: true,
    nsccParticipantNo: "3164",
    name: "Altruist",
  },
  ...primaryBrokerageList,
].sort((a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
});
