import { colors, lb } from "@frec-js/common";

import {
  DirectIndexType,
  SubAccount,
  SubAccountType,
} from "../../generated/graphql";
import { hexToHsl, hslToHex } from "../../utils";

export const getDirectIndexColor = (type?: DirectIndexType) => {
  switch (type) {
    case DirectIndexType.SpInfoTech:
      return colors.frecSalmon;
    case DirectIndexType.Sp500:
      return colors.frecBlue;
    case DirectIndexType.SpAdrDm:
      return colors.frecPlum;
    case DirectIndexType.SpAdrEm:
      return colors.frecSeaGreen;
    case DirectIndexType.SpShariah:
      return colors.frecBronze;
    case DirectIndexType.CrspLargeCap:
      return colors.frecPurple;
    case DirectIndexType.CrspMidCap:
      return colors.frecDarkTeal;
    case DirectIndexType.CrspSmallCap:
      return colors.frecTeal;
    case DirectIndexType.CrspTotalMarket:
      return colors.frecGrape;
    case DirectIndexType.CrspIssLargeCapEsg:
      return colors.frecAppleGreen;
    case DirectIndexType.Smh:
      return colors.frecDenimBlue;
    case DirectIndexType.Russell_1000:
      return colors.frecRust;
    case DirectIndexType.Russell_2000:
      return colors.frecMagenta;
    case DirectIndexType.Russell_3000:
      return colors.frecDustyRose;
    default:
      return colors.frecBlue;
  }
};

export const getSubAccountColor = (subAccount?: Partial<SubAccount>) => {
  switch (subAccount?.type) {
    case SubAccountType.DirectIndex:
      return getDirectIndexColor(subAccount?.directIndexAccountMetadata?.type);
    case SubAccountType.Treasury:
      return colors.frecTreasury;
    default:
      return colors.frecBlue;
  }
};

const generateAnalogousColors = (color: string) => {
  const [h, s, l] = hexToHsl(color);
  const leftAnalogous = hslToHex((h + 30) % 360, s, l);
  const rightAnalogous = hslToHex((h - 30 + 360) % 360, s, l);
  return [color, leftAnalogous, rightAnalogous];
};

const generateMonochromaticColors = (color: string, n: number) => {
  const [h, s, l] = hexToHsl(color);
  const colors = [];
  let start = 0;
  if (l > 0.5) {
    start = -3; // Higher the light value, the lighter the starting color, the lower the start
  } else if (l === 0.5) {
    start = -2;
  } else if (l > 0.25 && l < 0.5) {
    start = -1; // Smaller the light value, the darker the starting color, the higher the start
  }
  // Adjust start depending on number to generate: more colors = start darker, fewer colors = start lighter
  if (n >= 1 && n < 3) {
    start += 3;
  } else if (n >= 3 && n < 5) {
    start += 2;
  } else if (n === 5) {
    start += 1;
  } else if (n === 7) {
    start -= 1;
  } else if (n >= 8 && n < 10) {
    start -= 2;
  } else if (n >= 10 && n < 12) {
    start -= 3;
  } // Add more cases as needed
  for (let i = start; i <= n; i += 2) {
    const newL = Math.min(Math.max(l * (1 + i / (n + 1)), 0), 1);
    const newH = Math.min(newL > 0.5 ? h + 10 : h, 359); // max hue is 359
    const newS = lb(newL > 0.5 ? s - 0.05 : s);
    colors.push(hslToHex(newH, newS, newL));
  }
  return colors;
};

export const useDirectIndexingColorPalette = (
  type?: DirectIndexType,
  numColors?: number,
) => {
  const numMonochromaticColors = numColors ? Math.ceil(numColors / 2) : 8;
  const directIndexColor = getDirectIndexColor(type);

  // Generate analogous colors and monochromatic palettes for each
  const analogousColors = generateAnalogousColors(directIndexColor);
  const monochromaticPalettes: string[][] = [];
  analogousColors.forEach((color) => {
    monochromaticPalettes.push(
      generateMonochromaticColors(color, numMonochromaticColors),
    );
  });

  // Mix colors from monochromatic palettes from darkest to lightest
  const directIndexColorPalette = [];
  for (let i = 0; i <= numMonochromaticColors; i++) {
    directIndexColorPalette.push(
      monochromaticPalettes[0][i],
      monochromaticPalettes[1][i],
      monochromaticPalettes[2][i],
    );
  }

  return directIndexColorPalette;
};
