import { ScheduledDepositPeriodType } from "../generated/graphql";
import { formatUsd } from "../utils/number";

export const RECURRING_DEPOSIT_FREQUENCY_OPTIONS = [
  {
    label: "Monthly",
    value: ScheduledDepositPeriodType.Monthly,
  },
  {
    label: "Weekly",
    value: ScheduledDepositPeriodType.Weekly,
  },
  {
    label: "On the 1st and 15th",
    value: ScheduledDepositPeriodType.BiWeekly,
  },
];

export const RECURRING_DEPOSIT_FREQUENCY_LABEL: Record<
  ScheduledDepositPeriodType,
  string
> = {
  MONTHLY: "Monthly",
  WEEKLY: "Weekly",
  BI_WEEKLY: "On the 1st and 15th",
  QUARTERLY: "Quarterly",
  NONE: "", // internal only
};

export const recurringDepositMinMaxErrorMessage = (
  value: number | "",
  min: number,
  max: number,
) => {
  if (value && value < min) {
    return `The minimum amount for recurring deposits is ${formatUsd(min)}.`;
  }
  if (value && value > max) {
    return `The maximum amount for recurring deposits is ${formatUsd(max)}.`;
  }
  return undefined;
};
