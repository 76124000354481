import Decimal from "decimal.js";
import {
  getNextDepositDate,
  getNextDepositDateOnly,
  validateScheduledDepositConfig,
  ValidateScheduledDepositConfigArgs,
  ValidateScheduledDepositConfigValidationResult,
} from "./scheduledDepositUtils";
import { ZERO } from "../utils";
import {
  AllocationConfigCashTransfer,
  AllocationConfigCashTransferInput,
  AllocationConfigType,
  AllocationWithdrawalStrategy,
  ScheduledDepositConfig,
  ScheduledDepositPeriodType,
} from "../generated/graphql";
import { BUSINESS_TIMEZONE, DateOnly } from "../date_utils";

export const getTotalAllocationPercentage = (
  cashTransfers: {
    allocationSubAccountId: string;
    percentage: Decimal;
  }[],
  purchaseOrders: {
    securityId: string;
    percentage: Decimal;
  }[],
): Decimal => {
  return cashTransfers
    .reduce((acc, curr) => acc.plus(curr.percentage), ZERO)
    .plus(
      purchaseOrders.reduce((acc, curr) => acc.plus(curr.percentage), ZERO),
    );
};

export enum ValidateAllocationConfigResultEnum {
  PercentageSumInvalid = "PercentageSumInvalid",
  PercentageInvalid = "PercentageInvalid",
  PortfolioRebalancePurchaseOrdersPresent = "PortfolioRebalancePurchaseOrdersPresent",
  PublicApiCashTransfersPresent = "PublicApiCashTransfersPresent",
  ScheduledDepositConfigInvalid = "ScheduledDepositConfigInvalid",
  SubAccountIdsNotUnique = "SubAccountIdsNotUnique",
  SecurityIdsNotUnique = "SecurityIdsNotUnique",
  SourceSubAccountInCashTransfers = "SourceSubAccountInCashTransfers",
}

export type ValidateAllocationConfigResult = {
  allocationConfigErrors: ValidateAllocationConfigResultEnum[];
  scheduledDepositConfigErrors?: ValidateScheduledDepositConfigValidationResult[];
};

export type ValidateAllocationConfigArgs = {
  type: AllocationConfigType;
  cashTransfers: {
    allocationSubAccountId: string;
    percentage: Decimal;
  }[];
  purchaseOrders: {
    securityId: string;
    percentage: Decimal;
  }[];
  scheduledDepositConfig?: Omit<
    ValidateScheduledDepositConfigArgs,
    "subAccountId"
  >[];
};

export const validateAllocationConfig = (
  args: ValidateAllocationConfigArgs,
): ValidateAllocationConfigResult => {
  const results = new Set<ValidateAllocationConfigResultEnum>();

  // allocation percentages must be positive
  if (args.cashTransfers.some((c) => c.percentage.lte(ZERO))) {
    results.add(ValidateAllocationConfigResultEnum.PercentageInvalid);
  }

  if (args.purchaseOrders.some((p) => p.percentage.lte(ZERO))) {
    results.add(ValidateAllocationConfigResultEnum.PercentageInvalid);
  }

  // Allocation percentages must sum to 100%
  const totalPercentage = getTotalAllocationPercentage(
    args.cashTransfers,
    args.purchaseOrders,
  );
  if (!totalPercentage.eq(new Decimal(100))) {
    results.add(ValidateAllocationConfigResultEnum.PercentageSumInvalid);
  }

  // subAccountIds should be unique
  const subAccountIds = new Set(
    args.cashTransfers.map((c) => c.allocationSubAccountId),
  );
  if (subAccountIds.size !== args.cashTransfers.length) {
    results.add(ValidateAllocationConfigResultEnum.SubAccountIdsNotUnique);
  }
  // securityIds should be unique
  const securityIds = new Set(args.purchaseOrders.map((p) => p.securityId));
  if (securityIds.size !== args.purchaseOrders.length) {
    results.add(ValidateAllocationConfigResultEnum.SecurityIdsNotUnique);
  }

  // cash transfers should not be present if type is PUBLIC_API
  if (
    args.type === AllocationConfigType.PublicApi &&
    args.cashTransfers.length > 0
  ) {
    results.add(
      ValidateAllocationConfigResultEnum.PublicApiCashTransfersPresent,
    );
  }
  // orders should not be present if type is PORTFOLIO_REBALANCE
  if (
    args.type === AllocationConfigType.PortfolioRebalance &&
    args.purchaseOrders.length > 0
  ) {
    results.add(
      ValidateAllocationConfigResultEnum.PortfolioRebalancePurchaseOrdersPresent,
    );
  }

  // validate scheduled deposit configs
  let scheduledDepositConfigErrors:
    | ValidateScheduledDepositConfigValidationResult[]
    | undefined;
  if (args.scheduledDepositConfig) {
    args.scheduledDepositConfig.forEach((sd) => {
      const scheduledConfigResult = validateScheduledDepositConfig({
        ...sd,
        // All scheduled deposit configs for allocations are for the primary sub account
        subAccountId: "primarySubAccountId",
      });
      if (scheduledConfigResult.length > 0) {
        if (!scheduledDepositConfigErrors) {
          scheduledDepositConfigErrors = [];
        }
        scheduledDepositConfigErrors.push(...scheduledConfigResult);
        results.add(
          ValidateAllocationConfigResultEnum.ScheduledDepositConfigInvalid,
        );
      }
      // Source account in scheduled deposit should not be included in cash transfers
      if (
        sd.sourceSubAccountId !== undefined &&
        args.cashTransfers.some(
          (c) => c.allocationSubAccountId === sd.sourceSubAccountId,
        )
      ) {
        results.add(
          ValidateAllocationConfigResultEnum.SourceSubAccountInCashTransfers,
        );
      }
    });
  }

  return {
    allocationConfigErrors: Array.from(results),
    scheduledDepositConfigErrors,
  };
};

export type NormalizedAllocationConfig = Omit<
  ValidateAllocationConfigArgs,
  "scheduledDepositConfigs"
>;

/**
 * Normalizes the allocation config percentages to ensure that they sum to 100%
 */
export const normalizeAllocationConfig = (
  args: NormalizedAllocationConfig,
  decimalPlaces = 2,
): NormalizedAllocationConfig => {
  // if allocation doesn't sum to 100, normalize by percentage
  const totalPercentage = getTotalAllocationPercentage(
    args.cashTransfers,
    args.purchaseOrders,
  );

  // Create new arrays with normalized percentages
  let normalizedCashTransfers = args.cashTransfers;
  let normalizedPurchaseOrders = args.purchaseOrders;

  // ensure that this works for cases like 33 + 33 + 33
  // this is to prevent floating point precision issues
  if (!totalPercentage.eq(100)) {
    normalizedCashTransfers = args.cashTransfers.map((c) => ({
      ...c,
      percentage: c.percentage
        .div(totalPercentage)
        .mul(100)
        .toDP(decimalPlaces),
    }));
    normalizedPurchaseOrders = args.purchaseOrders.map((p) => ({
      ...p,
      percentage: p.percentage
        .div(totalPercentage)
        .mul(100)
        .toDP(decimalPlaces),
    }));
  }

  // Calculate the new total after normalization
  const newTotal = getTotalAllocationPercentage(
    normalizedCashTransfers,
    normalizedPurchaseOrders,
  );

  // If we're not exactly at 100%, adjust the largest allocation
  if (!newTotal.eq(100)) {
    const difference = new Decimal(100).minus(newTotal);
    const allAllocations = [
      ...normalizedCashTransfers,
      ...normalizedPurchaseOrders,
    ];
    const largest = allAllocations.reduce((max, curr) =>
      curr.percentage.gt(max.percentage) ? curr : max,
    );
    largest.percentage = largest.percentage.plus(difference);
  }

  return {
    ...args,
    cashTransfers: normalizedCashTransfers,
    purchaseOrders: normalizedPurchaseOrders,
  };
};

export type PortfolioAllocationValue = {
  cashTransfers: {
    subAccountId: string;
    value: Decimal;
  }[];
};

export type PortfolioAllocation = {
  cashTransfers: {
    allocationSubAccountId: string;
    percentage: Decimal;
  }[];
};

/**
 * Given a portfolio allocation value, determines the current allocation percentage
 */
export const determineAllocationPercentage = (
  allocationValue: PortfolioAllocationValue,
  decimalPlaces = 2,
): PortfolioAllocation => {
  const totalValue = allocationValue.cashTransfers.reduce(
    (acc, curr) => acc.plus(curr.value),
    ZERO,
  );

  // Handle floating point precision issues to make sure it adds up to 100%
  const cashTransfers = allocationValue.cashTransfers.map((c) => ({
    allocationSubAccountId: c.subAccountId,
    percentage: totalValue.eq(0)
      ? ZERO
      : c.value.div(totalValue).mul(100).toDP(decimalPlaces),
  }));
  // TODO: implement this for purchase orders
  const totalPercentage = getTotalAllocationPercentage(cashTransfers, []);

  // If we're not exactly at 100%, adjust the largest allocation
  if (!totalPercentage.eq(100) && !totalPercentage.isZero()) {
    const difference = new Decimal(100).minus(totalPercentage);
    const largest = cashTransfers.reduce((max, curr) =>
      curr.percentage.gt(max.percentage) ? curr : max,
    );
    largest.percentage = largest.percentage.plus(difference);
  }

  return {
    cashTransfers: cashTransfers,
  };
};

const filterPortfolioAllocationValue = (
  portfolioAllocationValue: PortfolioAllocationValue,
  targetAllocation: PortfolioAllocation,
): PortfolioAllocationValue => {
  return {
    ...portfolioAllocationValue,
    cashTransfers: portfolioAllocationValue.cashTransfers.filter((ct) =>
      targetAllocation.cashTransfers.some(
        (t) => t.allocationSubAccountId === ct.subAccountId,
      ),
    ),
  };
};

export type AllocationRebalanceAmount = {
  allocationSubAccountId: string;
  amountRequired: Decimal;
  targetPercentage: Decimal;
  currentPercentage: Decimal;
};

/**
 * Given a current allocation value and target allocation, keeping the most
 * overallocated sub account fixed, gives the amount
 * required to be deposited to each sub account to get close to the target allocation
 */
export const amountRequiredForRebalance = (
  currentAllocationValue: PortfolioAllocationValue,
  targetAllocation: PortfolioAllocation,
  isForDeposit: boolean,
): AllocationRebalanceAmount[] => {
  // Filter out sub accounts that are not in the target allocation
  const filteredCurrentAllocationValue = filterPortfolioAllocationValue(
    currentAllocationValue,
    targetAllocation,
  );

  const currentSubAccountValueMap = new Map(
    filteredCurrentAllocationValue.cashTransfers.map((ct) => [
      ct.subAccountId,
      ct.value,
    ]),
  );

  const currentAllocationPercentageMap = new Map(
    determineAllocationPercentage(
      filteredCurrentAllocationValue,
    ).cashTransfers.map((ct) => [ct.allocationSubAccountId, ct.percentage]),
  );

  // Find the highest value account relative to its target percentage
  // This will be our reference point for calculating the target total
  let referenceValue = isForDeposit ? ZERO : new Decimal(Infinity);
  targetAllocation.cashTransfers.forEach((target) => {
    const currentValue =
      currentSubAccountValueMap.get(target.allocationSubAccountId) || ZERO;
    // Calculate what the total should be if this account was at its target percentage
    const totalNeeded = currentValue.mul(100).div(target.percentage).toDP(2);

    if (isForDeposit) {
      referenceValue = Decimal.max(referenceValue, totalNeeded);
    } else {
      // For withdrawals, use the minimum reference value
      referenceValue = Decimal.min(referenceValue, totalNeeded);
    }
  });

  const result = targetAllocation.cashTransfers.map((target) => {
    const currentValue =
      currentSubAccountValueMap.get(target.allocationSubAccountId) || ZERO;
    const currentPercentage =
      currentAllocationPercentageMap.get(target.allocationSubAccountId) || ZERO;
    const targetValue = referenceValue.mul(target.percentage).div(100).toDP(2);
    const amountRequired = targetValue.minus(currentValue).toDP(2);

    return {
      allocationSubAccountId: target.allocationSubAccountId,
      // For withdrawals, we only want to withdraw from overallocated accounts
      amountRequired: isForDeposit
        ? amountRequired.gt(ZERO)
          ? amountRequired.round()
          : ZERO
        : amountRequired.lt(ZERO)
          ? amountRequired.abs().round()
          : ZERO,
      targetPercentage: target.percentage,
      currentPercentage,
    };
  });

  // sort by amount required, largest first
  return result.sort((a, b) => b.amountRequired.comparedTo(a.amountRequired));
};

/**
 * Calculate the amount of each sub account to sell (negative value) and
 * buy (positive value) to bring the current allocation to the target allocation.
 */
export const buyAndSellToRebalance = (
  currentAllocationValue: PortfolioAllocationValue,
  targetAllocation: PortfolioAllocation,
): AllocationRebalanceAmount[] => {
  // Filter out sub accounts that are not in the target allocation
  const filteredCurrentAllocationValue = filterPortfolioAllocationValue(
    currentAllocationValue,
    targetAllocation,
  );

  const currentSubAccountValueMap = new Map(
    filteredCurrentAllocationValue.cashTransfers.map((ct) => [
      ct.subAccountId,
      ct.value,
    ]),
  );

  const currentAllocationPercentageMap = new Map(
    determineAllocationPercentage(
      filteredCurrentAllocationValue,
    ).cashTransfers.map((ct) => [ct.allocationSubAccountId, ct.percentage]),
  );

  const totalCurrentAllocationValue =
    filteredCurrentAllocationValue.cashTransfers.reduce(
      (acc, curr) => acc.plus(curr.value),
      ZERO,
    );

  // Calculate the difference between the current allocation and the target allocation values
  // This is the amount of each sub account to sell (negative value) or buy (positive value)
  // difference = targetValue at current allocation total - currentValue
  //            = targetPercentage * totalCurrentAllocationValue - currentValue
  return targetAllocation.cashTransfers.map((target) => {
    const currentValue =
      currentSubAccountValueMap.get(target.allocationSubAccountId) ?? ZERO;
    const currentPercentage =
      currentAllocationPercentageMap.get(target.allocationSubAccountId) ?? ZERO;
    const targetValue = target.percentage
      .mul(totalCurrentAllocationValue)
      .div(100)
      .toDP(2);
    return {
      allocationSubAccountId: target.allocationSubAccountId,
      amountRequired: targetValue.minus(currentValue),
      targetPercentage: target.percentage,
      currentPercentage,
    };
  });
};

export type DepositAllocation = AllocationRebalanceAmount & {
  depositShare: Decimal;
  depositsNeeded: Decimal;
};

export type WithdrawalAllocation = AllocationRebalanceAmount & {
  withdrawalAmount: Decimal;
};

/**
 * Given a withdrawal amount, calculate how much to withdraw from each sub account
 * while bringing the current allocation to the target allocation, with the least
 * amount of withdrawals possible, but not necessarily the closest to the target allocation.
 */
export const distributeWithdrawalAmountToRebalanceFewestWithdrawals = (
  currentAllocationValue: PortfolioAllocationValue,
  targetAllocation: PortfolioAllocation,
  amount: Decimal,
): WithdrawalAllocation[] => {
  // Filter out sub accounts that are not in the target allocation
  const filteredCurrentAllocationValue = filterPortfolioAllocationValue(
    currentAllocationValue,
    targetAllocation,
  );

  // Get the required amounts for each subAccount
  const rebalanceAmounts = amountRequiredForRebalance(
    filteredCurrentAllocationValue,
    targetAllocation,
    false,
  );

  const currentSubAccountValueMap = new Map(
    filteredCurrentAllocationValue.cashTransfers.map((ct) => [
      ct.subAccountId,
      ct.value,
    ]),
  );
  // Calculate total rebalance amount available
  const totalOverAllocationAmount = rebalanceAmounts.reduce(
    (sum, ra) => sum.plus(ra.amountRequired),
    ZERO,
  );
  let remainingWithdrawalAmount = amount;
  const leftOver = amount.gt(totalOverAllocationAmount)
    ? amount.minus(totalOverAllocationAmount)
    : ZERO;

  let totalWithdrawalAmount = ZERO;
  const withdrawalAllocation = rebalanceAmounts.map((ra) => {
    const currentValue =
      currentSubAccountValueMap.get(ra.allocationSubAccountId) ?? ZERO;

    // First handle the rebalancing portion
    let withdrawalAmount = ZERO;
    if (remainingWithdrawalAmount.gt(ZERO) && ra.amountRequired.gt(ZERO)) {
      withdrawalAmount = Decimal.min(
        remainingWithdrawalAmount,
        ra.amountRequired,
        currentValue,
      ).toDP(2);
      remainingWithdrawalAmount =
        remainingWithdrawalAmount.minus(withdrawalAmount);
    }
    // Then handle any leftover amount proportionally based on target percentages
    if (leftOver.gt(ZERO)) {
      const leftOverShare = leftOver.mul(ra.targetPercentage).div(100);
      const totalWithdrawal = Decimal.min(
        withdrawalAmount.plus(leftOverShare),
        currentValue,
      ).toDP(2);
      withdrawalAmount = totalWithdrawal;
    }

    totalWithdrawalAmount = totalWithdrawalAmount.plus(withdrawalAmount);

    return {
      ...ra,
      withdrawalAmount,
    };
  });

  // If we're not exactly at the amount, adjust the largest withdrawal amount
  if (!totalWithdrawalAmount.eq(amount) && !totalWithdrawalAmount.isZero()) {
    const difference = amount.minus(totalWithdrawalAmount);
    const largest = withdrawalAllocation.reduce((max, curr) =>
      curr.withdrawalAmount.gt(max.withdrawalAmount) ? curr : max,
    );
    largest.withdrawalAmount = largest.withdrawalAmount.plus(difference);
  }

  return withdrawalAllocation;
};

export const distributeWithdrawalAmountToRebalance = (
  currentAllocationValue: PortfolioAllocationValue,
  targetAllocation: PortfolioAllocation,
  amount: Decimal,
  strategy: AllocationWithdrawalStrategy,
): WithdrawalAllocation[] => {
  switch (strategy) {
    case AllocationWithdrawalStrategy.MinimizeTrackingError:
      return distributeWithdrawalAmountToRebalanceClosestToTarget(
        currentAllocationValue,
        targetAllocation,
        amount,
      );
    case AllocationWithdrawalStrategy.OverallocatedAccountFirst:
      return distributeWithdrawalAmountToRebalanceFewestWithdrawals(
        currentAllocationValue,
        targetAllocation,
        amount,
      );
    default:
      // Todo: We can also use a default strategy here
      return [];
  }
};

/**
 * Given a withdrawal amount, calculate how much to withdraw from each sub account
 * while bringing the current allocation to the target allocation as close as possible.
 */
export const distributeWithdrawalAmountToRebalanceClosestToTarget = (
  currentAllocationValue: PortfolioAllocationValue,
  targetAllocation: PortfolioAllocation,
  amount: Decimal,
): WithdrawalAllocation[] => {
  // Filter out sub accounts that are not in the target allocation
  const filteredCurrentAllocationValue = filterPortfolioAllocationValue(
    currentAllocationValue,
    targetAllocation,
  );

  const totalCurrentAllocationValue =
    filteredCurrentAllocationValue.cashTransfers.reduce(
      (acc, curr) => acc.plus(curr.value),
      ZERO,
    );
  const valueAfterWithdraw = totalCurrentAllocationValue.minus(amount);

  // Get the required amounts for each subAccount
  const rebalanceAmounts = amountRequiredForRebalance(
    filteredCurrentAllocationValue,
    targetAllocation,
    false,
  );

  const currentSubAccountValueMap = new Map(
    filteredCurrentAllocationValue.cashTransfers.map((ct) => [
      ct.subAccountId,
      ct.value,
    ]),
  );

  // Calculate the difference between the target value and the current value
  const diffMap = new Map(
    rebalanceAmounts.map((ra) => {
      const targetValue = ra.targetPercentage.mul(valueAfterWithdraw).div(100);
      const currentValue = currentSubAccountValueMap.get(
        ra.allocationSubAccountId,
      );
      // Take the minimum since we want to withdraw from overallocated accounts
      const diff = currentValue
        ? Decimal.min(0, targetValue.minus(currentValue))
        : ZERO;
      // Use absolute value to ensure the total diff is positive
      return [ra.allocationSubAccountId, diff.abs()];
    }),
  );
  const totalDiff = Array.from(diffMap.values()).reduce(
    (sum, curr) => sum.plus(curr),
    ZERO,
  );

  // Calculate the withdrawal amount for each sub account:
  // withdrawalAmount = diff / totalDiff * amount
  // This ensures that the withdrawal amount is proportional to the difference
  // and that the total withdrawal amount is equal to the amount
  let totalWithdrawalAmount = ZERO;

  const withdrawalAllocation = rebalanceAmounts.map((ra) => {
    const diff = diffMap.get(ra.allocationSubAccountId) ?? ZERO;
    const withdrawalAmount = totalDiff.isZero()
      ? ZERO
      : diff.mul(amount).div(totalDiff).toDP(2);
    totalWithdrawalAmount = totalWithdrawalAmount.plus(withdrawalAmount);

    return {
      ...ra,
      withdrawalAmount,
    };
  });

  // If we're not exactly at the amount, adjust the largest withdrawal amount
  if (!totalWithdrawalAmount.eq(amount) && !totalWithdrawalAmount.isZero()) {
    const difference = amount.minus(totalWithdrawalAmount);
    const largest = withdrawalAllocation.reduce((max, curr) =>
      curr.withdrawalAmount.gt(max.withdrawalAmount) ? curr : max,
    );
    largest.withdrawalAmount = largest.withdrawalAmount.plus(difference);
  }

  return withdrawalAllocation;
};

/***
 * Given a deposit amount, distribute it to the sub accounts to get close to the target allocation
 */
export const distributeDepositAmountToRebalance = (
  currentAllocationValue: PortfolioAllocationValue,
  targetAllocation: PortfolioAllocation,
  amount: Decimal,
): DepositAllocation[] => {
  // Filter out sub accounts that are not in the target allocation
  const filteredCurrentAllocationValue = filterPortfolioAllocationValue(
    currentAllocationValue,
    targetAllocation,
  );

  // Get the required amounts for each subAccount
  const rebalanceAmounts = amountRequiredForRebalance(
    filteredCurrentAllocationValue,
    targetAllocation,
    true,
  );

  // Calculate total rebalance amount needed
  const totalRebalanceNeeded = rebalanceAmounts.reduce(
    (sum, ra) => sum.plus(ra.amountRequired),
    ZERO,
  );
  const leftOver = amount.gt(totalRebalanceNeeded)
    ? amount.minus(totalRebalanceNeeded)
    : ZERO;
  let totalDepositShare = ZERO;

  const depositAllocation = rebalanceAmounts.map((ra) => {
    let depositShare = ZERO;

    // When the deposit amount is more than the total rebalance needed, we allocate the left over amount by target percentage
    if (amount.gt(totalRebalanceNeeded)) {
      depositShare = ra.amountRequired.plus(
        leftOver.mul(ra.targetPercentage).div(100).toDP(2),
      );
    } else {
      // When deposit amount is less than the total rebalance needed,
      // calculate this account's share of each deposit based on its portion of total needed
      depositShare = totalRebalanceNeeded.eq(ZERO)
        ? ZERO
        : amount.mul(ra.amountRequired).div(totalRebalanceNeeded).toDP(2);
    }
    totalDepositShare = totalDepositShare.plus(depositShare);

    return {
      allocationSubAccountId: ra.allocationSubAccountId,
      amountRequired: ra.amountRequired,
      targetPercentage: ra.targetPercentage,
      currentPercentage: ra.currentPercentage,
      depositShare,
      depositsNeeded: depositShare.gte(ra.amountRequired)
        ? ZERO
        : /**
           * Ideally Decimal.ceil would be used here, but it rounds up to the next integer
           * which is not always correct. For example, 1000/333.33=rounds up to 4
           * So we're using a manual rounding method instead.
           */
          depositShare.isZero()
          ? ZERO
          : ra.amountRequired
              .div(depositShare)
              .toDP(0, Decimal.ROUND_HALF_CEIL),
    };
  });

  // If we're not exactly at the amount, adjust the largest deposit share
  if (!totalDepositShare.eq(amount) && !totalDepositShare.isZero()) {
    const difference = amount.minus(totalDepositShare);
    const largest = depositAllocation.reduce((max, curr) =>
      curr.depositShare.gt(max.depositShare) ? curr : max,
    );
    largest.depositShare = largest.depositShare.plus(difference);
  }

  return depositAllocation;
};

export type RebalanceCompleteDate = {
  allocationSubAccountId: string;
  daysUntilCompletionFromToday: number;
  depositsNeeded: number;
  dateUntilCompletion: DateOnly | null;
};

export const getRebalanceCompleteDates = (
  currentAllocationValue: PortfolioAllocationValue,
  targetAllocation: PortfolioAllocation,
  scheduledDepositConfig: Pick<
    ScheduledDepositConfig,
    "amount" | "dayOfPeriod" | "secondaryDayOfPeriod" | "periodType" | "startAt"
  >,
): RebalanceCompleteDate[] => {
  // Filter out sub accounts that are not in the target allocation
  const filteredCurrentAllocationValue = filterPortfolioAllocationValue(
    currentAllocationValue,
    targetAllocation,
  );

  const depositAmounts = distributeDepositAmountToRebalance(
    filteredCurrentAllocationValue,
    targetAllocation,
    scheduledDepositConfig.amount,
  );

  return depositAmounts.map((ra) => {
    if (!ra.amountRequired.gt(ZERO)) {
      return {
        allocationSubAccountId: ra.allocationSubAccountId,
        daysUntilCompletionFromToday: 0,
        depositsNeeded: 0,
        dateUntilCompletion: null,
      };
    }
    // Calculate completion date
    const nextDepositDate = getNextDepositDate({
      dayOfPeriod: scheduledDepositConfig.dayOfPeriod ?? 1,
      secondaryDayOfPeriod:
        scheduledDepositConfig.secondaryDayOfPeriod ?? undefined,
      periodType: scheduledDepositConfig.periodType,
      startAt: DateOnly.fromDateTz(
        scheduledDepositConfig.startAt,
        BUSINESS_TIMEZONE,
      ),
    });
    // Calculate total days needed
    let depositsLeft = ra.depositsNeeded.minus(1);
    let lastDepositDate = nextDepositDate;
    while (depositsLeft.gt(0)) {
      lastDepositDate = getNextDepositDateOnly({
        referenceDate: lastDepositDate.nextDay(),
        periodType: scheduledDepositConfig.periodType,
        dayOfPeriod: scheduledDepositConfig.dayOfPeriod ?? 1,
        secondaryDayOfPeriod:
          scheduledDepositConfig.secondaryDayOfPeriod ?? undefined,
        lastDepositDate,
      });
      depositsLeft = depositsLeft.minus(1);
    }

    const totalDaysNeeded = lastDepositDate.diff(nextDepositDate, "days");

    const today = DateOnly.now(BUSINESS_TIMEZONE);

    const daysUntilDepositStarts = Decimal.max(
      nextDepositDate.diff(today, "days"),
      0,
    ).toNumber();

    return {
      allocationSubAccountId: ra.allocationSubAccountId,
      daysUntilCompletionFromToday:
        scheduledDepositConfig.periodType === ScheduledDepositPeriodType.None
          ? 0
          : totalDaysNeeded + daysUntilDepositStarts,
      depositsNeeded: ra.depositsNeeded.toNumber(),
      dateUntilCompletion:
        scheduledDepositConfig.periodType === ScheduledDepositPeriodType.None
          ? null
          : lastDepositDate,
    };
  });
};

export const convertAllocationConfigCashTransfersToInput = (
  cashTransfers?: AllocationConfigCashTransfer[] | null,
): AllocationConfigCashTransferInput[] => {
  return (
    cashTransfers?.map((ct) => ({
      percentage: ct.percentage,
      allocationSubAccountId: ct.allocationSubAccountId,
    })) ?? []
  );
};
