// Schema limit here: https://github.com/Frec-Markets/frec/blob/d0cb9d799db72310f0a318e47d8059c770250302/js/packages/app-server/prisma/schema.prisma#L2993
// This is intentionally lower than DB limits since we have overwrite prevention logic in document upload service that adds more identifiers to the name
export const MAX_UPLOADED_FILE_NAME_LENGTH = 150; // including the extension
// There are no schema limits for the description, but we want to prevent users from putting arbitrary long descriptions
export const MAX_USER_UPLOADED_DESCRIPTION_LENGTH = 200;
// max upload file size in bytes; 12MB
export const MAX_FILE_SIZE = 12 * 1024 * 1024; // bytes

// get the size of a base64 string in bytes
export const getBase64SizeInBytes = (base64String: string) => {
  const base64 = base64String.split(",")[1] || base64String;

  // Calculate the size
  const sizeInBytes = Buffer.from(base64, "base64").length;
  return sizeInBytes;
};
