export enum FeatureFlags {
  ApexMaintenanceWindow = "apex-maintenance-window",
  /**
   * FREC-3134: See https://frectalk.slack.com/archives/C05UQML76U8/p1703613215894049 for context
   */
  BypassPaymentSupportedInstitutionChecks = "bypass-payments-supported-institutions-check",
  BankAccountLimit = "bank_account_limit",
  ThemeToggle = "theme-toggle",
  EstimatedTaxImpact = "estimated-tax-impact",
  DiCustomizationLimitOverride = "di-customization-limit-override",
  PLOCM1 = "ploc-m1",
  JointAccountM0 = "joint-account-m-0",
  Allocation = "portfolio-allocation",
}
