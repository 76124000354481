import type { GetServerSideProps } from "next";
import Head from "next/head";
import { FC } from "react";

import { FootnoteAnchor, IndexFootnotes } from "../components/Anchor";
import { DarkSection } from "../components/DarkSection";
import { Disclaimers } from "../components/Disclaimers";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { StartInvestingButton } from "../components/StartInvestingButton";
import { BrokerCheck } from "../sections/BrokerCheck";
import { ChooseFromMultipleIndices } from "../sections/home/ChooseFromMultipleIndices";
import { DirectIndexCalculator } from "../sections/home/DirectIndexCalculator";
import { DirectIndexSophisticatedInvesting } from "../sections/home/DirectIndexSophisticatedInvesting";
import { DirectIndexTable } from "../sections/home/DirectIndexTable";
import { Faq } from "../sections/home/Faq";
import { LandingHero } from "../sections/home/LandingHero";
import { LearnMore } from "../sections/home/LearnMore";
import { NextLevelInvesting } from "../sections/home/NextLevelInvesting";
import { Press } from "../sections/home/Press";
import { RoboadvisorBanner } from "../sections/home/RoboadvisorBanner";
import { ScheduleCall } from "../sections/home/ScheduleCall";
import { Testimonials } from "../sections/home/Testimonials";
import { TLHBanner } from "../sections/home/TLHBanner";
import { Treasury } from "../sections/home/Treasury";
import { TrustSecurityPrivacy } from "../sections/home/TrustSecurityPrivacy";
import { getBlogPosts, PostType } from "../utils/blogApi";
import { getServerSidePropsHelper } from "../utils/serverSidePropsHelper";
import { BannerEnhancedAlgo } from "../sections/home/BannerEnhancedAlgo";

type IndexProps = {
  isMobile: boolean;
  posts: PostType[] | null;
  isKnownUser: boolean;
};

const Index: FC<IndexProps> = ({ isMobile, posts, isKnownUser }) => {
  const title = "Frec - Index investing that gets you more";
  const description =
    "Frec is making sophisticated investing strategies simple and accessible. Low-cost direct indexing with supercharged tax savings. Earn high interest on cash with Treasury. Next-level investing without a wealth advisor.";

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href="https://frec.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta
          property="og:video"
          content="https://www.youtube.com/v/N7gRfWuZ6Mg"
        />
        <meta
          property="og:image"
          content="https://frec.com/webstatic/images/share-img.png"
          key="og:image"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <>
        <BannerEnhancedAlgo />
        <Header
          className="sticky top-0 z-50"
          isKnownUser={isKnownUser}
          notFixed
        />
        <main>
          <LandingHero />
          <RoboadvisorBanner />
          <DarkSection className="relative">
            {/* Dark Sections aren't happy being next to each other, so we keep these in one */}
            <NextLevelInvesting isMobile={isMobile} />
            <TLHBanner />
            <Press />
            {posts ? <Testimonials posts={posts} /> : null}
            <div className="bg-frecBeige flex justify-center py-12 lg:pb-14 lg:pt-0">
              <StartInvestingButton />
            </div>
            <TrustSecurityPrivacy />
          </DarkSection>
          <ChooseFromMultipleIndices />
          <DirectIndexCalculator />
          <DirectIndexTable />
          <DirectIndexSophisticatedInvesting />
          <Treasury />
          {posts ? <LearnMore posts={posts} /> : null}
          <Faq />
          <ScheduleCall />
        </main>
        <BrokerCheck />
        <Footer />
        <Disclaimers
          disclaimers={[
            [
              FootnoteAnchor.TwoXMoreDisclaimer,
              IndexFootnotes.withTwoXMoreDisclaimer,
            ],
            [
              FootnoteAnchor.LowerFeesDisclaimerSPY,
              IndexFootnotes.withLowerFeesDisclaimerSPY,
            ],
            [
              FootnoteAnchor.TreasuryDisclaimer,
              IndexFootnotes.withTreasuryDisclaimer,
            ],
            [FootnoteAnchor.ApexDisclaimer, IndexFootnotes.withApexDisclaimer],
            [FootnoteAnchor.FortyPercentDisclaimer, true],
          ]}
        />
      </>
    </>
  );
};

export const getServerSideProps: GetServerSideProps<IndexProps> = async ({
  req,
}) => {
  const props = getServerSidePropsHelper(req);
  const posts = await getBlogPosts();
  return Promise.resolve({
    props: { ...props, posts },
  });
};

export default Index;
