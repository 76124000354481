import { makeDecimal } from "@frec-js/common";
import Decimal from "decimal.js";
import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react";

import { TransferAccount } from "../../types";

interface InCtxTransferFlowProps {
  amount: Decimal;
  setAmount: (d: Decimal) => void;
  account?: TransferAccount;
  setAccount: Dispatch<SetStateAction<TransferAccount | undefined>>;
}

const InCtxTransferFlowContext = createContext<InCtxTransferFlowProps>({
  amount: makeDecimal(0),
  setAmount: () => undefined,
  account: undefined,
  setAccount: () => undefined,
});

/**
 * Used within a InCtxTransferFlowContextProvider.
 */
export const useInCtxTransferFlow = () => {
  const context = useContext<InCtxTransferFlowProps>(InCtxTransferFlowContext);
  return context;
};

/**
 * Use this provider when one of the source/destination accounts is known,
 * for example Direct Indexing deposit and withdraw flows.
 */
export const InCtxTransferFlowContextProvider = ({
  children,
}: PropsWithChildren) => {
  const [account, setAccount] = useState<TransferAccount | undefined>();
  const [amount, setAmount] = useState<Decimal>(makeDecimal(0));

  const value = useMemo<InCtxTransferFlowProps>(() => {
    return {
      amount,
      setAmount,
      account,
      setAccount,
    };
  }, [account, amount]);

  return (
    <InCtxTransferFlowContext.Provider value={value}>
      {children}
    </InCtxTransferFlowContext.Provider>
  );
};
