import Image from "next/image";
import { FC } from "react";

import { Button } from "../../components/Button";
import LicensedProfessionals from "../../public/webstatic/images/team/licensed-professionals.png";
import { classNames } from "../../utils/classNames";
import { windowIfAvailable } from "../../utils/windowIfAvailable";

export const ScheduleCall: FC<{
  title?: string;
  description?: string;
  className?: string;
}> = ({
  title = "Have questions?",
  description = "Meet with one of our licensed professionals who can give you an overview of Frec, answer your questions, and get you started with a free portfolio analysis.",
  className,
}) => {
  return (
    <section
      className={classNames(
        "grid grid-cols-[auto_minmax(0,_86rem)_auto]",
        className,
      )}
    >
      {/* row */}
      <div className=""></div>
      <div className="dotted-v grid grid-rows-[auto_auto] lg:mx-12 lg:grid-cols-2 lg:grid-rows-none lg:border-x">
        <div className="dotted-v flex h-full flex-col justify-center gap-8 px-6 pt-12 lg:ml-12 lg:border-r lg:pb-24 lg:pt-24">
          <h2 className="h2">{title}</h2>
          <p className="lg:text-book20">{description}</p>
          <Button
            onClick={() => windowIfAvailable?.open("/15-min", "_blank")}
            className="w-full lg:w-fit"
            variant="blueoutline"
          >
            Schedule a call
          </Button>
        </div>
        <div className="px-6 py-12">
          <div className="relative flex h-full min-h-[200px] flex-col items-center justify-center overflow-x-hidden lg:p-12">
            <Image
              src={LicensedProfessionals}
              className="h-full w-full object-contain"
              alt="Licensed Professionals"
            />
          </div>
        </div>
      </div>
      <div className="">{/* empty */}</div>
    </section>
  );
};
