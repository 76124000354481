import {
  ActivityListItemTypeKey,
  CreditType,
  IntraAccountCashTransferDirection,
  IntraAccountStockTransferDirection,
  LoanType,
  SubAccount,
  SubAccountType,
  UserReferralDeal,
} from "../generated/graphql";
import { DirectIndexingTypeShortText } from "./directIndex";

const activityListItemTypes: Record<
  Exclude<ActivityListItemTypeKey, ActivityListItemTypeKey.Credit>,
  string
> = {
  [ActivityListItemTypeKey.AcatsIn]: "Stock transfer",
  [ActivityListItemTypeKey.AllocationRebalance]: "Rebalance",
  [ActivityListItemTypeKey.AllocationWithdrawal]: "Withdrawal",
  [ActivityListItemTypeKey.AcatsOut]: "Outgoing stock transfer",
  [ActivityListItemTypeKey.Buy]: "Buy",
  [ActivityListItemTypeKey.CashSettlement]: "Cash settlement",
  [ActivityListItemTypeKey.CreditCardPayment]: "Credit card payment",
  [ActivityListItemTypeKey.Deposit]: "Deposit",
  [ActivityListItemTypeKey.DividendPayment]: "Dividend payment",
  [ActivityListItemTypeKey.DividendReinvestment]: "Dividend reinvestment",
  [ActivityListItemTypeKey.InterestCharge]: "Interest charge",
  [ActivityListItemTypeKey.InterestPayment]: "Interest payment",
  [ActivityListItemTypeKey.Loan]: "Loan",
  [ActivityListItemTypeKey.LoanPayment]: "Loan payment",
  [ActivityListItemTypeKey.PendingLoan]: "Loan",
  [ActivityListItemTypeKey.PendingLoanPayment]: "Loan payment",
  [ActivityListItemTypeKey.Sell]: "Sell",
  [ActivityListItemTypeKey.Withdrawal]: "Withdrawal",
  [ActivityListItemTypeKey.IntraAccountCashTransfer]: "Internal transfer",
  [ActivityListItemTypeKey.IntraAccountStockTransfer]:
    "Internal stock transfer",
  [ActivityListItemTypeKey.LiquidateDirectIndexRequest]:
    "Liquidate direct index",
  [ActivityListItemTypeKey.DirectIndexingTradeExecution]:
    "Direct index trade execution",
  [ActivityListItemTypeKey.DirectIndexingFeeCharge]: "Direct index fee charge",
};

export const getCreditActivityListItemTitle = (
  creditType: CreditType,
  inviteCodeDeal?: UserReferralDeal,
) => {
  switch (creditType) {
    case CreditType.PromoCredit:
      if (
        inviteCodeDeal &&
        inviteCodeDeal === UserReferralDeal.DiAumManagedForNoFees
      ) {
        return "Credit";
      }
      return "Referral bonus deposit";
    case CreditType.Courtesy:
    case CreditType.FeeReversal:
    case CreditType.Rebate:
    case CreditType.WriteOff:
    case CreditType.ZeroingAccount:
    default:
      return "Credit";
  }
};

export const getCreditActivityListItemReason = (
  creditType: CreditType,
  inviteCodeDeal?: UserReferralDeal,
): string | undefined => {
  switch (creditType) {
    case CreditType.PromoCredit:
      if (
        inviteCodeDeal &&
        inviteCodeDeal === UserReferralDeal.DiAumManagedForNoFees
      ) {
        return "Reward: $75k managed free";
      }
      return undefined;
    case CreditType.Courtesy:
    case CreditType.FeeReversal:
    case CreditType.Rebate:
    case CreditType.WriteOff:
    case CreditType.ZeroingAccount:
    default:
      return undefined;
  }
};

export const getActivityListItemTitle = (
  key: keyof typeof activityListItemTypes,
) => {
  return activityListItemTypes[key];
};

export const subAccountName: Record<SubAccountType, string> = {
  [SubAccountType.DirectIndex]: "Direct Indexing",
  [SubAccountType.Treasury]: "Treasury",
  [SubAccountType.Primary]: "Self-managed portfolio",
};

export const getSubAccountLabel = (subAccount: SubAccount) => {
  if (
    subAccount.type === SubAccountType.DirectIndex &&
    subAccount.directIndexAccountMetadata?.type
  ) {
    return `${subAccountName[subAccount.type]} - ${
      DirectIndexingTypeShortText[subAccount.directIndexAccountMetadata.type]
    }`;
  }

  return subAccountName[subAccount.type];
};

export const getSubAccountName = (
  subAccount?: Pick<SubAccount, "type" | "directIndexAccountMetadata">,
) => {
  if (!subAccount) {
    return "";
  }

  if (
    subAccount.type === SubAccountType.DirectIndex &&
    subAccount.directIndexAccountMetadata?.type
  ) {
    return `${
      DirectIndexingTypeShortText[subAccount.directIndexAccountMetadata.type]
    } index`;
  }

  return subAccountName[subAccount.type];
};

export const cashTransferDirectionMap = {
  [IntraAccountCashTransferDirection.Allocation]: "Allocation",
  [IntraAccountCashTransferDirection.Treasury]: "Treasury",
  [IntraAccountCashTransferDirection.DirectIndex]: "Direct Index Portfolio",
  [IntraAccountCashTransferDirection.FrecCash]: "Cash",
};

export const stockTransferDirectionMap = {
  [IntraAccountStockTransferDirection.DirectIndex]: "Direct Index Portfolio",
  [IntraAccountStockTransferDirection.Primary]: "Self-managed portfolio",
};

export const DISPLAY_LOAN_TYPES = new Set([
  LoanType.AcatsTransferredLoan,
  LoanType.LoanRepayment,
  LoanType.LoanRepaymentDividendSweep,
  LoanType.LoanRepaymentMarginInterest,
  LoanType.UserInitiated,
]);
