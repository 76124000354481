import { IndexMetadataTag } from "../generated/graphql";

export const IndexMarketCapFilterMap = new Map([
  ["All Cap", [IndexMetadataTag.MarketCapAllCap]],
  ["Large Cap", [IndexMetadataTag.MarketCapLargeCap]],
  ["Mid Cap", [IndexMetadataTag.MarketCapMidCap]],
  ["Small Cap", [IndexMetadataTag.MarketCapSmallCap]],
]);

export const IndexSectorFilterMap = new Map([
  ["Technology", [IndexMetadataTag.SectorTechnology]],
]);

export const IndexProviderFilterMap = new Map([
  ["S&P", [IndexMetadataTag.ProviderSp]],
  ["Russell", [IndexMetadataTag.ProviderRussell]],
  ["CRSP", [IndexMetadataTag.ProviderCrsp]],
  ["MVIS", [IndexMetadataTag.ProviderMvis]],
]);

export const IndexRegionFilterMap = new Map([
  ["International", [IndexMetadataTag.RegionInternational]],
]);

export const IndexValuesFilterMap = new Map([
  ["ESG", [IndexMetadataTag.ValuesEsg]],
  ["Shariah", [IndexMetadataTag.ValuesShariah]],
]);
