import Decimal from "decimal.js";
import xirr from "xirr";

import { DateOnly } from "../date_utils";
import {
  DirectIndexStatus,
  SecuritySubType,
  SecurityType,
} from "../generated/graphql";
import { TargetWeight } from "../simulation/direct_index/index";
import { PromiseExtensions, ZERO } from "../utils";

export type ValidateDirectIndexSupportedSecurityArgs = {
  type: SecurityType;
  subType: SecuritySubType;
  canTradeFractionally: boolean;
};

export enum ValidateDirectIndexSupportedError {
  IsNotEquity = "IsNotEquity",
  SubtypeMustBeCommonStock = "SubtypeMustBeCommonStock",
  MustSupportFractionalTrading = "MustSupportFractionalTrading",
}

export enum ValidateDirectIndexSupported {
  Ok = "ok",
}

/**
 * Either a non-empty array of errors or "ok"
 */
export type ValidateDirectIndexSupportedSecurityResult =
  | [ValidateDirectIndexSupportedError, ...ValidateDirectIndexSupportedError[]]
  | ValidateDirectIndexSupported.Ok;

export const validateDirectIndexSupportedSecurity = ({
  type,
  subType,
  canTradeFractionally,
}: ValidateDirectIndexSupportedSecurityArgs): ValidateDirectIndexSupportedSecurityResult => {
  const isEquity =
    type !== SecurityType.Equity
      ? [ValidateDirectIndexSupportedError.IsNotEquity]
      : [];

  const isCommonStock =
    subType !== SecuritySubType.CommonStock
      ? [ValidateDirectIndexSupportedError.SubtypeMustBeCommonStock]
      : [];

  const isTradeableFractionally = canTradeFractionally
    ? []
    : [ValidateDirectIndexSupportedError.MustSupportFractionalTrading];

  const [head, ...tail] = [
    ...isEquity,
    ...isCommonStock,
    ...isTradeableFractionally,
  ];
  return head ? [head, ...tail] : ValidateDirectIndexSupported.Ok;
};

/**
 * Cash flow includes:
 *
 * Outflows (-ve):
 * - The price paid for any investment (fees)
 * - Reinvested dividends or interest (DRIP purchases)
 * - Withdrawals (cash removed from the portfolio by the investor)
 *
 * Inflows (+ve):
 * - The proceeds from any investment sold (cash that remains in the portfolio from any shares sold)
 * - Dividends or interest received (cash earned from investments)
 * - Deposits (cash injected into the portfolio by an investor)
 */
export type TimeWeightedReturnPortfolioEntry = {
  cashFlow: Decimal;
  // includes the impact of cashFlow
  portfolio: Decimal;
};

export type MoneyWeightedReturnTransactions = {
  asOfDate: Date;
  cashFlow: Decimal;
};

export type SimpleStock = {
  symbol: string;
  name: string;
};

/**
 * Used to produce a `targetWeight` function for a direct indexing, using
 * market cap data.
 */
export const marketCapWeighting = (
  marketCapLookup: (symbol: string, date: DateOnly) => Promise<Decimal>,
  symbolList: string[],
): ((d: DateOnly) => Promise<TargetWeight[]>) => {
  return async (d: DateOnly) => {
    if (symbolList.length === 0) {
      return [];
    }

    const marketCapList = await PromiseExtensions.traverseConcurrent(
      symbolList,
      (symbol) => marketCapLookup(symbol, d),
    );

    const totalCap = marketCapList.reduce((a, b) => a.plus(b), ZERO);
    if (totalCap.eq(ZERO)) {
      throw new Error("Invalid market cap data");
    }
    return symbolList.map((symbol, i) => ({
      symbol,
      weight: marketCapList[i].div(totalCap),
    }));
  };
};

/**
 * Calculates the time weighted return, it's the return of the period spanning the given `portfolioEntries`
 *
 * TimeWeightedReturn(TWR):  [(1+subPeriodReturn1) * (1 + subPeriodReturn2) * ... * (1 + subPeriodReturnN)] - 1
 *
 * where:
 *   subPeriodReturn = (PortFolioEndValue - (PortfolioStartValue + CashFlow)) / (PortfolioStartValue + CashFlow)
 *   CashFlow = (Inflows - Outflows)
 */
export function calculateTimeWeightedReturn(
  portfolioEntries: TimeWeightedReturnPortfolioEntry[],
): Decimal | undefined {
  try {
    const subPeriodReturns: Decimal[] = [];
    for (let i = 1; i < portfolioEntries.length; i++) {
      const currentEntry = portfolioEntries[i];
      const previousEntry = portfolioEntries[i - 1];

      const subPeriodReturn = currentEntry.portfolio
        .sub(previousEntry.portfolio.add(currentEntry.cashFlow))
        .div(previousEntry.portfolio);

      subPeriodReturns.push(subPeriodReturn);
    }

    return subPeriodReturns
      .reduce((acc, curr) => curr.add(1).mul(acc), new Decimal(1))
      .sub(1)
      .toDP(5);
  } catch (e) {
    return undefined;
  }
}

/**
 * Calculates the money weighted return, it's an annualized rate of return
 */
export function calculateMoneyWeighedReturn(
  transactions: MoneyWeightedReturnTransactions[],
): Decimal | undefined {
  try {
    const result = xirr(
      transactions.map((t) => ({
        amount: t.cashFlow.toNumber(),
        when: t.asOfDate,
      })),
    );
    return new Decimal(result).toDP(5);
  } catch (e) {
    return undefined;
  }
}

/**
 * Use this when your transactions span less than a year and you don't want an annualized return
 *
 * (1 + Annualized MWR)^(days between transactions / 365) - 1
 */
export function calculateMoneyWeightedReturnYTD(
  transactions: MoneyWeightedReturnTransactions[],
): Decimal | undefined {
  const mwrAnnualized = calculateMoneyWeighedReturn(transactions);
  if (mwrAnnualized === undefined || transactions.length === 0) {
    return mwrAnnualized;
  }

  // Convert to an effective rate for the year to date period
  // (1 + Annualized MWR)^(days between transactions / 365) - 1
  const firstTransactionDate = DateOnly.fromDateUTC(transactions[0].asOfDate);
  const lastTransactionDate = DateOnly.fromDateUTC(
    transactions[transactions.length - 1].asOfDate,
  );
  const daysInBetween = DateOnly.daysInBetween(
    lastTransactionDate,
    firstTransactionDate,
  );

  return mwrAnnualized
    .plus(1)
    .pow(new Decimal(daysInBetween).div(365))
    .sub(1)
    .toDP(5);
}

/**
 * Direct index setup is in progress if the DirectIndexStatus is one of the following:
 * - InitialFundingPending
 * - InitialFundingFailed
 * - Init
 */
export function isDirectIndexSetupInProgress(
  status?: DirectIndexStatus,
): boolean {
  return (
    status === DirectIndexStatus.InitialFundingPending ||
    status === DirectIndexStatus.InitialFundingFailed ||
    status === DirectIndexStatus.Init
  );
}
