export const DocumentLinks = {
  "New Account Agreement": "https://docs.frec.com/new-account-agreement.pdf",

  "New Account Agreement Entity":
    "https://docs.frec.com/entity-account-agreement.pdf",

  "Customer Agreement": "https://docs.frec.com/customer-agreement.pdf",

  "Margin Agreement": "https://docs.frec.com/margin-agreement.pdf",

  "Margin Disclosure": "https://docs.frec.com/margin-disclosure.pdf",

  "Business Continuity Statement":
    "https://docs.frec.com/business-continuity-statement.pdf",

  "Privacy Policy": "https://docs.frec.com/privacy-policy.pdf", // see also next.config.js

  "Terms of Use": "https://docs.frec.com/terms-of-use.pdf", // see also next.config.js

  "Form CRS": "https://docs.frec.com/form-crs.pdf",

  "Rule 606 & 607 Disclosure":
    "https://docs.frec.com/rule-606-607-disclosure.pdf",

  "Frec’s Pricing & Fee Schedule": `https://docs.frec.com/pricing-fee-schedule.pdf`,

  "Pattern Day Trading Disclosures":
    "https://docs.frec.com/pattern-day-trading-disclosure.pdf",

  "Risks of Investing Disclosure":
    "https://docs.frec.com/risks-of-investing-disclosure.pdf",

  "SIPC Disclosure": "https://docs.frec.com/sipc-disclosure.pdf",

  "Corporation Account Form":
    "https://docs.frec.com/corporation-account-form.pdf",

  "LLC Account Form": "https://docs.frec.com/llc-account-form.pdf",

  "Partnership Form": "https://docs.frec.com/partnership-account-form.pdf",

  "Investment Management Agreement":
    "https://docs.frec.com/investment-management-agreement.pdf",

  "Form ADV": "https://docs.frec.com/form-adv.pdf",

  "RIA Authorization Form": "https://docs.frec.com/ria-authorization-form.pdf",

  "Frec 2024 New Year Bonus Terms & Conditions":
    "https://docs.frec.com/frec-2024-new-year-bonus.pdf",

  "Treasury Strategy Disclosure":
    "https://docs.frec.com/treasury-strategy-disclosure.pdf",

  "Frec Disclosures": "https://docs.frec.com/frec-disclosures.pdf",

  "Referral Program Terms & Conditions":
    "https://docs.frec.com/250_referral_terms.pdf",

  "Frec Social Media Disclosure":
    "https://docs.frec.com/social-media-disclosure.pdf",

  "Frec One Sided $250 DI Bonus Promotion":
    "https://docs.frec.com/frec-one-sided-250-bonus-promotion.pdf",

  "Frec Long Angle $500 DI Bonus Promotion":
    "https://docs.frec.com/frec-long-angle-500-promotion.pdf",

  "Frec Hampton $500 DI Bonus Promotion":
    "https://docs.frec.com/frec-hampton-500-promotion.pdf",

  "Frec All-In summit":
    "https://docs.frec.com/frec-all-In-summit-promotion.pdf",

  "Frec Metrix $250 DI Bonus Promotion":
    "https://docs.frec.com/frec-metrix-250-promotion.pdf",

  // External event giveaways
  "Frec Long Angle giveaway":
    "https://docs.frec.com/frec-long-angle-giveaway.pdf",
  "Frec Stocktwit giveaway":
    "https://docs.frec.com/frec-stocktwit-giveaway.pdf",
  "Frec SOHN SF giveaway": "https://docs.frec.com/frec-sohn-sf-giveaway.pdf",
  "Frec WOBI giveaway": "https://docs.frec.com/frec-wobi-giveaway.pdf",

  // DI AUM managed for free
  "Long Angle AUM managed Terms & Conditions":
    "https://docs.frec.com/frec-aum-promo-long-angle.pdf",
  "Gelt AUM managed Terms & Conditions":
    "https://docs.frec.com/frec-aum-promo-gelt.pdf",
  "Lowenstein AUM managed Terms & Conditions":
    "https://docs.frec.com/frec-aum-promo-lowenstein.pdf",

  // Benchmarks for indices
  "SMH Benchmark": "https://docs.frec.com/smh_benchmark.pdf",
  "Shariah Benchmark": "https://docs.frec.com/shariah_benchmark.pdf",
  "ESG Benchmark": "https://docs.frec.com/esg_benchmark.pdf",
  "ADR DM Benchmark": "https://docs.frec.com/sp_adr_dm_benchmark.pdf",
  "ADR EM Benchmark": "https://docs.frec.com/sp_adr_em_benchmark.pdf",
  "SP Benchmarks": "https://docs.frec.com/sp_benchmarks.pdf",
  "CRSP Benchmarks": "https://docs.frec.com/crsp_benchmarks.pdf",
  "Russell Benchmarks": "https://docs.frec.com/russell_benchmarks.pdf",

  // can be deprecated after August 24, 2024
  "Direct Index VV/VB vs. CRSP comparison":
    "https://docs.frec.com/vb_vv_vs_crsp.pdf",
  "Direct Index SMH tracking comparison":
    "https://docs.frec.com/smh-tracking-comparison.pdf",
  "Direct Index tracking comparison":
    "https://docs.frec.com/direct-indexing-tracking-comparison.pdf",
} as const;
