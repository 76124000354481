import Link from "next/link";
import { FC } from "react";

import { AnimatedIcon } from "../../components/Button";
import { classNames } from "@frec-js/common-web";
import Image from "next/image";
import { FrecWebPath } from "../../utils/paths";
import { useIsMobile } from "../../hooks/useIsMobile";

export const BannerEnhancedAlgo: FC<{ className?: string }> = ({
  className,
}) => {
  const isMobile = useIsMobile();
  return (
    <Link
      target="_blank"
      href={FrecWebPath.enhancedAlgo}
      className={classNames(
        "bg-frecNeon group flex h-9 w-full items-center justify-center gap-2 border-b !border-black px-2",
        className,
      )}
    >
      <span className="text-nowrap text-xs sm:text-sm">
        {isMobile
          ? "7-25% more losses with our enhanced algorithm"
          : "You now have the opportunity to harvest 7-25% more in capital losses with our enhanced algorithm at no extra cost"}
      </span>
      <Image
        alt="rocket ship"
        src="/webstatic/svg/trendup.svg"
        width={16}
        height={16}
      />
      <span className="flex items-center justify-center gap-1">
        <span className="sm:no-wrap w-min text-wrap text-xs font-medium !leading-3 sm:w-auto sm:text-sm">
          {isMobile ? "Learn more" : "Read our white paper"}
        </span>
        <AnimatedIcon small />
      </span>
    </Link>
  );
};
