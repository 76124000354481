import { useMemo } from "react";

import {
  AcatsQuery,
  AcatsTransferStatus,
  ClearingAccountFragment,
  useAcatsQuery,
} from "../generated/graphql";

export const ACATS_STATUS_NO_FURTHER_PROCESSING = [
  AcatsTransferStatus.Complete,
  AcatsTransferStatus.OpsRejected,
  AcatsTransferStatus.Purge,
];

export const transformAcatsData = (data?: Partial<AcatsQuery>) => {
  const acatsTransferItems =
    data?.clearingAccount?.getAccountAcatsTransfers?.data;

  const activeAcatsTransferItems = acatsTransferItems?.filter(
    (item) => !ACATS_STATUS_NO_FURTHER_PROCESSING.includes(item.currentStatus),
  );

  return {
    acats: acatsTransferItems ?? [],
    activeAcats: activeAcatsTransferItems ?? [],
  };
};

export const useAcatsApi = ({
  clearingAccount,
}: {
  clearingAccount?: ClearingAccountFragment;
}) => {
  const { data, loading, refetch } = useAcatsQuery({
    variables: { clearingAccountId: clearingAccount?.id || "" },
    skip: !clearingAccount,
  });

  return useMemo(
    () => ({
      ...transformAcatsData(data),
      loading,
      initialLoading: loading && !data,
      refetch,
    }),
    [data, loading, refetch],
  );
};
