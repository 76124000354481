import { DateOnly } from "@frec-js/common";
import Decimal from "decimal.js";

import { OrderType, QuantityType } from "../generated/graphql";

export type TradeType =
  | {
      type: OrderType.Market;
    }
  | {
      type: OrderType.Limit;
      limit: Decimal;
    };

export const QuantityTypeUnitLookup = {
  [QuantityType.Notional]: "dollar",
  [QuantityType.Fractional]: "share",
};

export type TradeFlowSelectedLot = {
  quantity: Decimal;
  tradeDate?: DateOnly;
  taxLotEntryId: string;
};
